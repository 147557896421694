import React from 'react';
import { List, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import { LoadingOutlined } from '@ant-design/icons';
import { FaLink } from 'react-icons/fa6';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import CopyIcon from '../Elements/CopyIcon';
import { RiRadioButtonLine } from "react-icons/ri";


const antIcon = (
    <LoadingOutlined
        style={{
            fontSize: 30,
        }}
        spin
    />
);


export default function TabelaDadosCarroProposta({ dataUser, urlCarroLink, dataCarros, isvIucData, propostaInfo }) {

    const { t } = useTranslation();

    console.log(dataCarros)

    return (
        <>
            <div className='flex flex-col gap-4 text-white font-semibold text-lg '>
                <div className='card grid gap-6'>

                    {(propostaInfo.propostaDisponivelOnline === 0 ||
                        propostaInfo.propostaDisponivelOnline === 1 ||
                        propostaInfo.propostaExpirou === 1) && (
                            <div className="flex items-center gap-4">
                                {propostaInfo.propostaDisponivelOnline === 0 && (
                                    <div className="rounded-full bg-[#156813] text-sm font-semibold px-3 w-auto inline-flex items-center text-white">
                                        <RiRadioButtonLine />
                                        <span className="ml-2">{t('tables.proposta_dispon_online')}</span>
                                    </div>
                                )}

                                {propostaInfo.propostaDisponivelOnline === 1 && (
                                    <div className="rounded-full bg-[#b63911] text-sm font-semibold px-3 w-auto inline-flex items-center text-white">
                                        <RiRadioButtonLine />
                                        <span className="ml-2">{t('tables.proposta_indispon_online')}</span>
                                    </div>
                                )}

                                {propostaInfo.propostaExpirou === 1 && (
                                    <div className="rounded-full bg-[#b63911] text-sm font-semibold px-3 w-auto inline-flex items-center text-white">
                                        <RiRadioButtonLine />
                                        <span className="ml-2">{t('tables.proposta_indispon_intern')}</span>
                                    </div>
                                )}
                            </div>
                        )}

                    <div className='grid grid-cols-1 md:grid-cols-1 gap-6 rounded-md'>
                        <div>
                            <Swiper
                                // autoplay={{
                                //     delay: 2500,
                                //     disableOnInteraction: false,
                                // }}
                                navigation={true}
                                modules={[Navigation]}
                            >
                                {dataCarros.imagens && dataCarros.imagens.length > 0 && dataCarros.imagens.map((imagem, index) => (
                                    <SwiperSlide key={index}>
                                        <img
                                            style={{ borderRadius: '8px', objectFit: 'cover', width: '100%' }}
                                            src={imagem}
                                            alt={`Image ${index + 1}`}
                                        />
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                            <div className="swiper-scrollbar swiper-scrollbar-horizontal"></div>
                        </div>
                        <div className='flex flex-col gap-6'>
                            <p className='font-medium text-2xl' style={{ overflowWrap: 'break-word' }}>
                                <b>{dataCarros.titulo}</b>
                            </p>

                            <div className="grid grid-cols-2 sm:grid-cols-3 gap-2">
                                {/* <p className='text-base text-neutral-500'>{t('tabela_viatura.preco')}: <br /><span className='text-lg text-white font-medium'>{isParticular ? formatarNumero(dataCarros.preco_final_carro) : formatarNumero(dataCarros.preco_final_carro_sem_iva)}€</span></p> */}
                                <p className='text-base text-neutral-500'>{t('tabela_viatura.quilometros')}: <br /><span className='text-lg text-white font-medium'>{dataCarros.quantidade_de_km}</span></p>
                                <p className='text-base text-neutral-500'>{t('tabela_viatura.data_registo')}: <br /><span className='text-lg text-white font-medium'>{isvIucData.dataPrimeiroRegisto || 'Sem informação'}</span></p>
                                {isvIucData.cilindrada ? (
                                    <p className='text-base text-neutral-500'>
                                        {t('tabela_viatura.cilindrada')}: <br />
                                        <span className='text-lg text-white font-medium'>
                                            {isvIucData.cilindrada} ccm
                                        </span>
                                    </p>
                                ) : null}
                                {/*</div> */}
                                {/*<div className='flex flex-col gap-4'>*/}
                                <p className='text-base text-neutral-500'>{t('tabela_viatura.potencia')}: <br /><span className='text-lg text-white font-medium'>
                                    {dataCarros.potencia?.toString().includes('Hp') ? dataCarros.potencia?.toString().replace('Hp', 'CV') : dataCarros?.potencia + ' CV'}
                                </span></p>
                                <p className='text-base text-neutral-500'>{t('tabela_viatura.combustivel')}: <br /><span className='text-lg text-white font-medium'>{isvIucData.tipoCombustivel}</span></p>
                                {isvIucData.tipoCombustivel !== "Eletrico" ? (
                                    <p className='text-base text-neutral-500'>
                                        {t('tabela_viatura.emissoes')}: <br />
                                        <span className='text-lg text-white font-medium'>
                                            {isvIucData.emissoesCO2}
                                        </span>
                                    </p>
                                ) : null}
                                {/*</div> */}
                            </div>
                        </div>
                    </div>
                    <div className='text-white mais-informacoes-box'>
                        <h2 className='pt-2 pr-4 pb-2'>{t('tabela_viatura.mais_informacao')}</h2>
                        <List
                            size="small"
                            dataSource={[
                                { title: `${t('tabela_viatura.acidentes')}`, value: dataCarros.acidentes },
                                { title: `${t('tabela_viatura.tipo_de_caixa')}`, value: dataCarros.tipo_de_caixa },
                                { title: `${t('tabela_viatura.stand')}`, value: propostaInfo.standData.nome_stand },
                                { title: `${t('tabela_viatura.pais')}`, value: propostaInfo.standData.pais_stand },
                                { title: `${t('tabela_viatura.codigo_postal')}`, value: propostaInfo.standData.codigo_postal_stand },
                                { title: `${t('tabela_viatura.morada')}`, value: propostaInfo.standData.morada_stand },
                                { title: `${t('tabela_viatura.contacto')}`, value: propostaInfo.standData.contacto_stand },
                                { title: `${t('tabela_viatura.contacto_2')}`, value: propostaInfo.standData.contacto_2_stand },
                            ]}
                            renderItem={(item) => (
                                <List.Item>
                                    <div className='grid grid-cols-2 gap-4 w-full'>
                                        <p className='text-xs sm:text-sm text-white'>{item.title}:</p>
                                        <span className='text-white text-xs font-normal sm:text-sm flex gap-2 items-center'>{item.value ? item.value : `${t('tabela_viatura.sem_informacao')}`}</span>
                                    </div>
                                </List.Item>
                            )}
                        />
                        <div className='grid grid-cols-2 gap-4 w-full border-t py-2'>
                            <p className='text-xs sm:text-sm text-white'>{t('tabela_viatura.ver_anuncio')}:</p>
                            <div className='flex items-center gap-4'>
                                <a href={propostaInfo.linkFora} target="_blank" className='text-white text-xs font-normal sm:text-sm flex gap-2 items-center'>
                                    Link
                                    <FaLink />
                                </a>
                                <label className='text-white text-xs font-normal sm:text-sm flex items-center'>
                                    Copiar
                                    <CopyIcon text={propostaInfo.linkFora} />
                                </label>
                            </div>

                        </div>
                    </div>
                </div>

                {dataUser.language == "pt" && (
                    propostaInfo.outros.estimativa_entrega && (
                        <div className='text-md border-card bg-black p-4'>
                            <div className='grid gap-4'>
                                <div className='grid gap-4'>
                                    <div className='flex flex-col gap-2 text-md'>
                                        <div className='flex w-full justify-between items-center'>
                                            <div className='flex gap-2 items-center'>
                                                <p className='text-xs sm:text-sm font-bold'>
                                                    Estimativa de entrega da viatura
                                                </p>
                                                <Tooltip title="Este prazo é indicativo e corresponde à duração prevista do processo, desde a aceitação da proposta até à entrega do carro. É calculado com base na média dos processos já concluídos.">
                                                    <AiOutlineInfoCircle className='icon-question' />
                                                </Tooltip>
                                            </div>
                                            <span className='text-white font-bold text-xs sm:text-sm'>
                                                {propostaInfo.outros.estimativa_entrega} semanas
                                            </span>
                                        </div>
                                        <hr />
                                        <div className='flex w-full justify-between items-center'>
                                            <div className='flex gap-2 items-center'>
                                                <p className='text-xs sm:text-sm text-neutral-500'>
                                                    Tempo de recolha
                                                </p>
                                                <Tooltip title="Este prazo é indicativo e corresponde ao período entre a aceitação da proposta e a recolha da viatura no stand, por parte da transportadora. É calculado com base na média dos processos já concluídos.">
                                                    <AiOutlineInfoCircle className='icon-question' />
                                                </Tooltip>
                                            </div>
                                            <span className='font-bold text-xs sm:text-sm text-neutral-500'>
                                                {propostaInfo.outros.tempo_recolha} semanas
                                            </span>
                                        </div>
                                        <div className='flex w-full justify-between items-center'>
                                            <div className='flex gap-2 items-center'>
                                                <p className='text-xs sm:text-sm text-neutral-500'>
                                                    Tempo de entrega
                                                </p>
                                                <Tooltip title="Este prazo é indicativo e corresponde ao período entre a chegada da viatura a Portugal e a entrega ao cliente. É calculado com base na média dos processos já concluídos.">
                                                    <AiOutlineInfoCircle className='icon-question' />
                                                </Tooltip>
                                            </div>
                                            <span className='font-bold text-xs sm:text-sm text-neutral-500'>
                                                {propostaInfo.outros.tempo_entrega} semanas
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                )}

            </div >
        </>
    );

};
