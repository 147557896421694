import React, { useState } from 'react';
import { Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import PropsPrecoFinalProposta from './PrecoFinalProposta/propsPrecoFinalProposta';

// const { TabPane } = Tabs;

export default function PrecoFinalProposta({ valoresTotais, setValoresTotais, dataUser }) {

    const { t } = useTranslation();

    const [activeTabKey, setActiveTabKey] = useState("1");



    const formatarNumero = (valor) => {
        if (!valor || isNaN(valor)) return '0 €';
        return new Intl.NumberFormat('pt-PT', {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(valor);
    };


    // Função para validar se o valor da viatura é igual em Particular e Empresa
    const validarValordaViatura = () => {
        if (valoresTotais.precoViaturaComIva === valoresTotais.precoViaturaSemIva) {
            return t('gerar_proposta.validar_valor_viatura'); // Retorna a string traduzida
        }
        return ''; // Retorna string vazia se não for igual
    };

    // Função chamada ao trocar de aba
    const onTabChange = (key) => {
        setActiveTabKey(key); // Atualiza o estado da aba ativa
    };



    const items = [
        {
            label: <span className='text-black'>{t('main.particular')}</span>,
            key: '1',
            children: (
                <PropsPrecoFinalProposta
                    setValoresTotais={setValoresTotais}
                    valoresTotais={valoresTotais}
                    incluirIVA={true} // Com IVA para Particular
                    activeTabKey={activeTabKey}
                    dataUser={dataUser}
                    formatarNumero={formatarNumero}
                />
            ),
        },
        {
            label: <span className='text-black'>{t('main.empresa')}</span>,
            key: '2',
            children: (
                <PropsPrecoFinalProposta
                    setValoresTotais={setValoresTotais}
                    valoresTotais={valoresTotais}
                    incluirIVA={false} // Sem IVA para Empresa
                    activeTabKey={activeTabKey}
                    dataUser={dataUser}
                    formatarNumero={formatarNumero}
                />
            ),
        },
    ];


    return (
        <div className='card grid gap-4'>
            <div className='flex justify-between items-center gap-4'>
                <div className='grid gap-1'>
                    <p className='text-lg font-semibold'>{t('gerar_proposta.resultado_simucao')}</p>
                </div>
            </div>
            <hr />

            {/* Tabs para Particular e Empresa */}
            <Tabs
                className='calculo-chave-na-mao'
                activeKey={activeTabKey}  // Controlando a aba ativa com o estado
                onChange={onTabChange}    // Atualizando o estado ao mudar de aba
                defaultActiveKey="1"
                tabBarGutter={16}
                centered
                items={items}             // Using items instead of TabPane
            />

            {/* Exibir a mensagem de validação se os valores forem iguais */}
            {validarValordaViatura() && (
                <>
                    <hr />
                    <div className="text-neutral-500 font-sm font-semibold text-center">
                        {validarValordaViatura()}
                    </div>
                </>
            )}
        </div>
    );
};