import React, { useState } from "react";
import { Select, Form, message } from "antd";
import axios from "axios";
import Loading from "../../../components/Elements/Loading";

const { Option } = Select;

export default function FormSelectsGerarDocumentos({ data, setData, dataUser, onFormChange }) {

    const [form] = Form.useForm();

    const [isContratoVisible, setIsContratoVisible] = useState(false); // Estado para controlar visibilidade do select de contrato


    const handleFormChange = (_, allValues) => {

        const { tipoDocumento, tipoSociedade, tipoContrato } = allValues;

        // Reset `data.fields` when `tipoDocumento` changes
        if (tipoDocumento !== data.currentTipoDocumento) {
            onFormChange({ fields: [] }); // Reseta os campos ao alterar `tipoDocumento`
            data.currentTipoDocumento = tipoDocumento; // Atualiza o documento atual para comparações futuras
            setData((prevState) => ({
                ...prevState,
                linkDownload: []
            }));
        }

        // Exibe o select de contratos apenas se o tipo de documento for 'contrato' ou 'novo_negocio'
        if (tipoDocumento === 'contrato' || tipoDocumento === 'novo_negocio') {
            setIsContratoVisible(true);
        } else {
            setIsContratoVisible(false);
            form.setFieldsValue({ tipoContrato: '' }); // Reseta o campo de contrato se o documento não for 'contrato'
        }

        // Condicional para incluir ou excluir o tipoContrato no pedido
        // tipoContrato deve ser enviado como null se o tipoDocumento não for 'contrato'
        if (tipoDocumento && tipoSociedade && (tipoDocumento !== 'contrato' || tipoContrato)) {
            // Todos os campos obrigatórios estão preenchidos
            const dataGerarDocumentos = {
                tipo_documento: tipoDocumento,
                tipo_sociedade: tipoSociedade,
                tipo_contrato: tipoDocumento === 'contrato' || tipoDocumento === 'novo_negocio' ? tipoContrato : null, // Se não for contrato, enviar null
                user_id: dataUser.iDTokenAuthenticated,
                user_token: dataUser.saveToken,
            };

            axios.get(`${process.env.REACT_APP_GET_CAMPOS_DOCUMENTOS}`, {
                params: dataGerarDocumentos // Passa os parâmetros via query string
            })
                .then((response) => {
                    // console.log(response);
                    if (response.data.sucesso) {
                        // Atualiza os campos no componente pai, mantendo os valores anteriores
                        // console.log(response.data.data.doc.fields)
                        onFormChange({
                            fields: response.data.data.doc.fields,
                            otherDocs: response.data.data.doc.gera_outros_docs || []
                        });
                    } else {
                        message.error(response.data.mensagem);
                    }
                })
                .catch((error) => {
                    message.error('Erro. Tente novamente!');
                });
        }

        // Dispara a função onFormChange para manter a lógica de formulário
        onFormChange(allValues);
    };


    if (data.tipos_documentos.length === 0) {
        return (
            <Loading />
        );
    }

    return (
        <Form
            form={form}
            onValuesChange={handleFormChange}
        >
            {data.tipos_documentos.length > 0 && (
                <div className={`flex gap-4 transition-all duration-500`} style={{ flexWrap: 'wrap' }}>
                    <div className='flex flex-col items-stretch gap-2 flex-1 transition-all duration-500'>
                        <p className='text-white font-semibold'>Selecione o Tipo de Documento</p>
                        <Form.Item
                            name="tipoDocumento"
                            rules={[{ required: true, message: "Selecione um documento" }]}
                        >
                            <Select
                                style={{ width: '100%' }}
                                placeholder="Selecione um documento"
                            >
                                {data.tipos_documentos.map((doc) => (
                                    <Option key={doc.slug} value={doc.slug}>
                                        {doc.nome}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </div>

                    <div className='flex flex-col items-stretch gap-2 flex-1 transition-all duration-500'>
                        <p className='text-white font-semibold'>Selecione o Tipo de Sociedade</p>
                        <Form.Item
                            name="tipoSociedade"
                            rules={[{ required: true, message: "Selecione uma sociedade" }]}
                        >
                            <Select
                                style={{ width: '100%' }}
                                placeholder="Selecione uma sociedade"
                            >
                                {data.tipos_sociedades.map((soc) => (
                                    <Option key={soc.slug} value={soc.slug}>
                                        {soc.nome}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </div>

                    {isContratoVisible && (
                        <div
                            className='flex flex-col items-stretch gap-2 flex-1 transition-all duration-500'
                            style={{ opacity: isContratoVisible ? 1 : 0, maxWidth: isContratoVisible ? '100%' : 0, overflow: 'hidden' }}
                        >
                            <p className='text-white font-semibold'>Selecione o Tipo de Contrato</p>
                            <Form.Item
                                name="tipoContrato"
                                rules={[{ required: isContratoVisible, message: "Selecione um contrato" }]}
                            >
                                <Select
                                    style={{ width: '100%' }}
                                    placeholder="Selecione um contrato"
                                >
                                    {data.tipos_contratos.map((contrato) => (
                                        <Option key={contrato.slug} value={contrato.slug}>
                                            {contrato.nome}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>
                    )}
                </div>
            )}
        </Form>
    );
};
