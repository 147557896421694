import React from 'react';
import ISVComponent from './compISV';
import PrecoChaveNaMaoProposta from './precoChaveNaMao';
import IUCComponent from './compIUC';
import { useTranslation } from 'react-i18next';
import ExpressInfoModal from '../ExpressInfoDrawer';

export default function PropsPrecoFinalProposta({ valoresTotais, incluirIVA, setValoresTotais, activeTabKey, dataUser, formatarNumero }) {

    // const formatarOuMostrarSemValor = (valor) => {
    //     return valor === '' || valor === 0 ? 'S/V' : formatarNumero(valor);
    // };

    const { t } = useTranslation();


    return (
        <div className='flex flex-col gap-1'>
            {/* Preço Viatura com IVA (apenas para Particular) */}
            {incluirIVA && (
                <div className='flex justify-between items-center'>
                    <p className='text-black text-base'>{t('gerar_proposta.valor_viatura')}</p>
                    <p className='font-bold text-lg'>
                        {formatarNumero(valoresTotais.precoViaturaComIva)}
                    </p>
                </div>
            )}

            {/* Preço Viatura sem IVA */}
            {!incluirIVA && (
                <div className='flex justify-between items-center'>
                    <p className='text-black text-base'>{t('gerar_proposta.valor_viatura')}</p>
                    <p className='font-bold text-lg'>
                        {formatarNumero(valoresTotais.precoViaturaSemIva)}
                    </p>
                </div>
            )}


            {/* Valor Total ISV */}
            <ISVComponent
                dataUser={dataUser}
                valoresTotais={valoresTotais}
                formatarNumero={formatarNumero}
            />


            {/* Taxa de Serviço */}
            <div className='flex justify-between items-center'>
                <p className='text-black text-base'>{t('gerar_proposta.taxa_servico')}</p>
                <p className='font-bold text-lg'>
                    {formatarNumero(valoresTotais.taxaDeServico)}
                </p>
            </div>

            {/* Taxa de Financiamento */}
            {valoresTotais.taxaDeFinanciamentoAtivo == 1 && (
                <div className='flex justify-between items-center'>
                    <p className='text-black text-base'>{t('gerar_proposta.taxa_financiamento')}</p>
                    <p className='font-bold text-lg'>
                        {formatarNumero(valoresTotais.taxaDeFinanciamento)}
                    </p>
                </div>
            )}

            {/* Taxa de Serviço Express */}
            {valoresTotais.valorExpressAtivo == 1 && (
                <div className='flex justify-between items-center'>
                    <span className='text-base flex items-center gap-2'>
                        Express
                        <ExpressInfoModal />
                    </span>
                    <p className='font-bold text-lg'>
                        {formatarNumero(valoresTotais.valorExpress)}
                    </p>
                </div>
            )}

            {/* Preço Chave na Mão */}
            <PrecoChaveNaMaoProposta
                activeTabKey={activeTabKey}
                valoresTotais={valoresTotais}
                setValoresTotais={setValoresTotais}
                formatarNumero={formatarNumero}
            />

            <hr></hr>

            {/* Valor Total IUC */}
            <IUCComponent
                formatarNumero={formatarNumero}
                dataUser={dataUser}
                valoresTotais={valoresTotais}
            />

        </div>
    );
};