import React, { useState } from 'react';
import { Button, Popover } from 'antd';
import { FiCopy, FiCheck, FiExternalLink } from 'react-icons/fi';
import { MdShare } from 'react-icons/md';
import ReactDOMServer from 'react-dom/server';
import { useTranslation } from 'react-i18next';

export default function PartilharClienteProposta({ propostaInfo, dataCarros, valoresTotais, titulo, formatarNumero }) {

    const { t } = useTranslation();
    const [copiedItem, setCopiedItem] = useState(null);

    console.log(valoresTotais)

    const handleOptionClick = (value) => {
        let text;

        if (value === 1) {
            text = (
                <div>
                    <a target="_blank" style={{ cursor: "pointer" }} href={propostaInfo.linkDaProposta}>
                        {`${dataCarros.titulo}`}
                    </a>
                    <br></br>
                    <div>(Particular)
                        <br></br>
                        {t('gerar_proposta.preco_carro')}: {`${formatarNumero(valoresTotais.precoViaturaComIva)}`} (Por Negociar)
                        <br></br>
                        <b>{`${t('gerar_proposta.preco_final_chave_mao')}: ${formatarNumero(valoresTotais.valorPrecoChaveNaMaoParticular)}`}</b>
                    </div>
                    <br></br>
                    <div>(Empresa)
                        <br></br>
                        {t('gerar_proposta.preco_carro')}: {`${formatarNumero(valoresTotais.precoViaturaSemIva)}`} (Por Negociar)
                        <br></br>
                        <b>{`${t('gerar_proposta.preco_final_chave_mao')}: ${formatarNumero(valoresTotais.valorPrecoChaveNaMaoEmpresa)}`}</b>
                    </div>
                    <hr></hr>
                    <div>
                        {`${t('gerar_proposta.isv')}: ${valoresTotais.valorTotalISV === "0"
                            ? t('gerar_proposta.isento')
                            : formatarNumero(valoresTotais.valorTotalISV)
                            }`}
                    </div>
                    <div>{`${t('gerar_proposta.despesas_de_importacao')}: ${formatarNumero(valoresTotais.taxaDeServico)}`}</div>
                    <div>
                        {`${t('gerar_proposta.iuc')}: ${valoresTotais.valorTotalIUC === "0"
                            ? t('gerar_proposta.isento')
                            : formatarNumero(valoresTotais.valorTotalIUC)
                            }`}
                    </div>
                </div>
            );

            const textString = ReactDOMServer.renderToStaticMarkup(text);

            const blob = new Blob([textString], { type: 'text/html' });

            navigator.clipboard.write([new ClipboardItem({ "text/html": blob })])
                .then(() => {

                })
                .catch((error) => {

                });

        } else if (value === 5) {

            text = (
                <div>
                    <a target="_blank" style={{ cursor: "pointer" }} href={propostaInfo.linkDaProposta}>
                        {`${dataCarros.titulo}`}
                    </a>
                    <br></br>
                    <div>(Personal)
                        <br></br>
                        {`${t('gerar_proposta.preco_carro_en')}: ${formatarNumero(valoresTotais.precoViaturaComIva)} (To be negotiated)`}
                        <br></br>
                        <b>{`${t('gerar_proposta.preco_final_chave_mao_en')}: ${formatarNumero(valoresTotais.valorPrecoChaveNaMaoParticular)}`}</b>
                    </div>
                    <br></br>
                    <div>(Company)
                        <br></br>
                        {`${t('gerar_proposta.preco_carro_en')}: ${formatarNumero(valoresTotais.precoViaturaSemIva)} (To be negotiated)`}
                        <br></br>
                        <b>{`${t('gerar_proposta.preco_final_chave_mao_en')}: ${formatarNumero(valoresTotais.valorPrecoChaveNaMaoEmpresa)}`}</b>
                    </div>
                    <hr></hr>
                    <div>
                        {`${t('gerar_proposta.isv')}: ${valoresTotais.valorTotalISV === "0"
                            ? t('gerar_proposta.isento_en')
                            : formatarNumero(valoresTotais.valorTotalISV)
                            }`}
                    </div>
                    <div>{`${t('gerar_proposta.despesas_de_importacao_en')}: ${formatarNumero(valoresTotais.taxaDeServico)}`}</div>
                    <div>
                        {`${t('gerar_proposta.iuc')}: ${valoresTotais.valorTotalIUC === "0"
                            ? t('gerar_proposta.isento_en')
                            : formatarNumero(valoresTotais.valorTotalIUC)
                            }`}
                    </div>
                </div>
            );

            const textString = ReactDOMServer.renderToStaticMarkup(text);

            const blob = new Blob([textString], { type: 'text/html' });

            navigator.clipboard.write([new ClipboardItem({ "text/html": blob })])
                .then(() => {

                })
                .catch((error) => {

                });

        } else if (value === 2) {

            let text = (
                <div><a href={propostaInfo.linkDaProposta}>{`${propostaInfo.linkDaProposta}`}</a></div>
            );

            const textString = ReactDOMServer.renderToStaticMarkup(text);

            const blob = new Blob([textString], { type: 'text/html' });

            navigator.clipboard.write([new ClipboardItem({ "text/html": blob })])
                .then(() => {

                })
                .catch((error) => {

                });

        } else if (value === 3) {

            const text = `${propostaInfo.linkDaProposta}`;

            const blob = new Blob([text], { type: 'text/plain' });

            navigator.clipboard.write([new ClipboardItem({ "text/plain": blob })])
                .then(() => {
                    // Copied successfully
                })
                .catch((error) => {
                    // Handle errors
                });
        } else {

        }

    };

    const handleCopy = (value) => {
        handleOptionClick(value);
        setCopiedItem(value); // Define o item copiado
        setTimeout(() => {
            setCopiedItem(null); // Limpa o estado de copiado após 2 segundos
        }, 2000);
    };

    return (
        <div className={`flex items-center ${titulo ? 'gap-4' : 'gap-1'}`}>
            <Popover
                content={
                    <div className="text-black grid gap-2">
                        {/* Copiar Texto para Email */}
                        <div className="flex items-center gap-2">
                            <label className="flex items-center gap-2 cursor-pointer">
                                {copiedItem === 1 ? (
                                    <FiCheck className="text-2xl text-black" title="Copiado!" />
                                ) : (
                                    <FiCopy
                                        className="text-2xl text-black"
                                        title={t('gerar_proposta.copiar_texto_para_email')}
                                        onClick={() => handleCopy(1)}
                                    />
                                )}
                                <span onClick={() => handleCopy(1)}>{t('gerar_proposta.copiar_texto_para_email')}</span>
                            </label>
                        </div>

                        {/* Copiar Texto para Email em Inglês */}
                        <div className="flex items-center gap-2">
                            <label className="flex items-center gap-2 cursor-pointer">
                                {copiedItem === 5 ? (
                                    <FiCheck className="text-2xl text-black" title="Copiado!" />
                                ) : (
                                    <FiCopy
                                        className="text-2xl text-black"
                                        title={t('gerar_proposta.copiar_texto_para_email_en')}
                                        onClick={() => handleCopy(5)}
                                    />
                                )}
                                <span onClick={() => handleCopy(5)}>{t('gerar_proposta.copiar_texto_para_email_en')}</span>
                            </label>
                        </div>

                        {/* Copiar URL com Link */}
                        <div className="flex items-center gap-2">
                            <label className="flex items-center gap-2 cursor-pointer">
                                {copiedItem === 2 ? (
                                    <FiCheck className="text-2xl text-black" title="Copiado!" />
                                ) : (
                                    <FiCopy
                                        className="text-2xl text-black"
                                        title={t('gerar_proposta.copiar_url_com_link')}
                                        onClick={() => handleCopy(2)}
                                    />
                                )}
                                <span onClick={() => handleCopy(2)}>{t('gerar_proposta.copiar_url_com_link')}</span>
                            </label>
                        </div>

                        {/* Copiar URL simples */}
                        <div className="flex items-center gap-2">
                            <label className="flex items-center gap-2 cursor-pointer">
                                {copiedItem === 3 ? (
                                    <FiCheck className="text-2xl text-black" title="Copiado!" />
                                ) : (
                                    <FiCopy
                                        className="text-2xl text-black"
                                        title={t('gerar_proposta.copiar_url')}
                                        onClick={() => handleCopy(3)}
                                    />
                                )}
                                <span onClick={() => handleCopy(3)}>{t('gerar_proposta.copiar_url')}</span>
                            </label>
                        </div>
                    </div>
                }
                title={t('gerar_proposta.partilhar_com_cliente')}
                trigger="click"
            >

                <Button
                    type="primary"
                    // onClick={showVisibleEditProposta}
                    className='shadow-none font-bold text-black bg-white'>
                    <div className='flex items-center gap-2'>
                        <MdShare />
                        {titulo ? t('gerar_proposta.partilhar_com_cliente') : null}
                    </div>
                </Button>

            </Popover>

            <Button
                type="primary"
                onClick={() => window.open(propostaInfo.linkDaProposta, '_blank')}
                className='shadow-none font-bold text-black bg-white'>
                <div className='flex items-center gap-2'>
                    <FiExternalLink />
                    {titulo ? t('gerar_proposta.abrir') : null}
                </div>
            </Button>
        </div>
    );
}
