import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { GoAlertFill } from 'react-icons/go';
import { FaEnvelope } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

export default function CardDestaqueEmailsFalhados({ dataUser, labelDataQuadro }) {
    const { t } = useTranslation();

    // Estado para armazenar o número de emails falhados
    const [countEmailsFalhados, setCountEmailsFalhados] = useState(null);

    useEffect(() => {
        // Verifica se as informações do usuário estão presentes antes de fazer a requisição
        if (dataUser?.iDTokenAuthenticated && dataUser?.saveToken) {

            const dataDeHoje = new Date().toISOString().split('T')[0]; // Formata a data atual

            const obterEmailsFalhados = {
                data_inicio: dataDeHoje,
                data_fim: dataDeHoje,
                nome_evento: 'Falha',
                user_id: dataUser.iDTokenAuthenticated,
                user_token: dataUser.saveToken,
                dashboard: '1'
            };

            axios.get(`${process.env.REACT_APP_OBTER_LOGS_EMAILS}`, { params: obterEmailsFalhados })
                .then((response) => {
                    setCountEmailsFalhados(response.data.count); // Atualiza o estado com o número de emails falhados
                })
                .catch((error) => {
                    console.error('Error occurred:', error);
                    setCountEmailsFalhados(0); // Em caso de erro, define como 0
                });
        }
    }, [dataUser]); // Atualiza o efeito quando `dataUser` mudar

    
    return (
        <div>
            {countEmailsFalhados !== null ? ( // Exibe o número quando disponível, caso contrário, exibe o skeleton
                <div className={`${countEmailsFalhados > 0 ? 'card-email-falhado' : 'card-email-falhado-zero'}`}>
                    <div className="flex w-full flex-row items-center justify-between space-y-0 pb-4">
                        <p className='text-sm font-medium'>{t('dashboard.emails_falhados_hoje')} <span className='lowercase'>{labelDataQuadro}</span></p>
                        {countEmailsFalhados > 0 ? (
                            <GoAlertFill size={20} />
                        ) : (
                            <FaEnvelope size={20} />
                        )}
                    </div>
                    <div className='flex gap-2 items-center'>
                        <span className='text-lg font-bold text-center'>
                            {countEmailsFalhados}
                        </span>
                    </div>
                </div>
            ) : (
                // Skeleton de carregamento
                <div className="bg-main animate-pulse p-4 rounded-lg shadow-lg">
                    <div className="h-16"></div>
                </div>
            )}
        </div>
    );
}
