import React from 'react';
import FormAlterarEstadoDaProposta from './EditarProposta/FormAlterarEstadoDaProposta';
import FromEditarProposta from './EditarProposta/FromEditarProposta';
import FormAlterarExpress from './EditarProposta/FormAlterarExpress';

export default function EditarProposta({
    dataUser,
    setPropostaInfo,
    propostaInfo,
    valoresTotais,
    setValoresTotais,
    isvIucData,
    setIsvIucData,
    formatarNumero
}) {


    return (
        <div className='flex flex-col mb-6'>

            {dataUser.language === 'pt' && propostaInfo.tipoProposta === "proposta_cliente" && (
                <>
                    <FormAlterarEstadoDaProposta
                        dataUser={dataUser}
                        setPropostaInfo={setPropostaInfo}
                        propostaInfo={propostaInfo}
                    />

                    <hr className='mb-6' />
                </>
            )}

            <FromEditarProposta
                dataUser={dataUser}
                propostaInfo={propostaInfo}
                valoresTotais={valoresTotais}
                setValoresTotais={setValoresTotais}
                isvIucData={isvIucData}
                setIsvIucData={setIsvIucData}
            />

            {dataUser.language === 'pt' && (
                <>
                    <hr className='mb-6' />

                    <FormAlterarExpress
                        formatarNumero={formatarNumero}
                        dataUser={dataUser}
                        propostaInfo={propostaInfo}
                        valoresTotais={valoresTotais}
                        setValoresTotais={setValoresTotais}
                    />
                </>
            )}

        </div>
    );
}
