import React, { useState } from 'react';
import { Form, Input, Select, Button, DatePicker, Checkbox, message, Space, Tabs } from 'antd';
import axios from 'axios';
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { FaFileAlt } from 'react-icons/fa';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import Loading from '../../Elements/Loading';
dayjs.extend(customParseFormat);

const { Option } = Select;

export default function TodosCamposGerarDocumentos({ fields, data, dataUser, otherDocs, setData, white }) {

    const [form] = Form.useForm();
    const [conditionalFields, setConditionalFields] = useState({});
    const [selectedDocs, setSelectedDocs] = useState([]);
    const [loading, setLoading] = useState(false);  // Estado de loading para gerar pdf


    // Declaração do estado para armazenar valores do código postal
    const [codigoPostalInicio, setCodigoPostalInicio] = useState('');
    const [codigoPostalFim, setCodigoPostalFim] = useState('');
    const [codigoPostalCompleto, setCodigoPostalCompleto] = useState('');

    // Função para atualizar o código postal
    const handleCodigoPostalChange = (value, part) => {
        if (part === 'inicio') {
            setCodigoPostalInicio(value);
            setCodigoPostalCompleto(`${value}-${codigoPostalFim}`);
        } else if (part === 'fim') {
            setCodigoPostalFim(value);
            setCodigoPostalCompleto(`${codigoPostalInicio}-${value}`);
        }
    };

    const handleCheckboxChange = (docId, checked) => {
        setTimeout(() => {
            setSelectedDocs(prev => (checked ? [...prev, docId] : prev.filter(id => id !== docId)));
        }, 50); // Adjust delay as needed (e.g., 50ms)
    };


    const handleSelectChange = (fieldSlug, value) => {
        const fieldArray = Array.isArray(fields) ? fields : Object.values(fields);
        const field = fieldArray.find(f => f.slug === fieldSlug);

        if (field && field.tipo === 'select') {
            const selectedOption = Object.values(field.opcoes).find(option => option.slug === value);

            if (selectedOption?.mostra) {
                setConditionalFields(prev => ({
                    ...prev,
                    [fieldSlug]: selectedOption.mostra,
                }));
            } else {
                setConditionalFields(prev => ({
                    ...prev,
                    [fieldSlug]: [],
                }));
            }
        }
    };

    const formatDate = (date) => {
        return date ? dayjs(date).format('DD/MM/YYYY') : '';
    };

    // Define os valores iniciais do formulário com base em `auto_complete_value`
    const initialValues = (Array.isArray(fields) ? fields : Object.values(fields)).reduce((acc, field) => {
        if (field.auto_complete_value) {
            acc[field.slug] = field.tipo === 'date' ? dayjs(field.auto_complete_value) : field.auto_complete_value;
        }
        return acc;
    }, {});


    const renderField = (field) => {
        switch (field.tipo) {
            case 'string':
                return (
                    <div className='flex flex-col items-stretch gap-2 flex-1' key={field.slug}>
                        <p className={`font-semibold ${white ? 'text-black ' : 'text-white '}`}>{field.nome}</p>
                        <Form.Item name={field.slug} rules={[{ required: true, message: `${field.nome} é obrigatório!` }]}>
                            <Input placeholder={`${field.nome}`} autoComplete='off' />
                        </Form.Item>
                    </div>
                );
            case 'number':
                return (
                    <div className='flex flex-col items-stretch gap-2 flex-1' key={field.slug}>
                        <p className={`font-semibold ${white ? 'text-black ' : 'text-white '}`}>{field.nome}</p>
                        <Form.Item name={field.slug} rules={[{ required: true, message: `${field.nome} é obrigatório!` }]}>
                            <Input type="number" placeholder={`${field.nome}`} autoComplete='off' />
                        </Form.Item>
                    </div>
                );
            case 'date':
                return (
                    <div className='flex flex-col items-stretch gap-2 flex-1' key={field.slug}>
                        <p className={`font-semibold ${white ? 'text-black ' : 'text-white '}`}>{field.nome}</p>
                        <Form.Item name={field.slug} rules={[{ required: true, message: `${field.nome} é obrigatório!` }]}>
                            <DatePicker style={{ width: '100%' }} format="YYYY-MM-DD" placeholder={`${field.nome}`} />
                        </Form.Item>
                    </div>
                );
            case 'select':
                return (
                    <div className='flex flex-col items-stretch gap-2 flex-1' key={field.slug}>
                        <p className={`font-semibold ${white ? 'text-black ' : 'text-white '}`}>{field.nome}</p>
                        <Form.Item name={field.slug} rules={[{ required: true, message: `${field.nome} é obrigatório!` }]}>
                            <Select placeholder={`Selecione o ${field.nome}`} onChange={(value) => handleSelectChange(field.slug, value)}>
                                {Object.entries(field.opcoes).map(([key, option]) => (
                                    <Option key={key} value={option.slug}>
                                        {option.nome}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </div>
                );
            case 'matricula_viatura':
                return (
                    <div className='flex flex-col items-stretch gap-2 flex-1' key={field.slug}>
                        <p className={`font-semibold ${white ? 'text-black ' : 'text-white '}`}>{field.nome}</p>
                        <Form.Item name={field.slug} rules={[{ required: true, message: `${field.nome} é obrigatório!` }]}>
                            <Input placeholder="XX-XX-XX" autoComplete='off' />
                        </Form.Item>
                    </div>
                );
            case 'zip_code':
                return (
                    <div className='flex flex-col items-stretch gap-2 flex-1' key={field.slug}>
                        <p className={`font-semibold ${white ? 'text-black ' : 'text-white '}`}>{field.nome}</p>
                        <Form.Item name={field.slug} rules={[{ required: true, message: `${field.nome} é obrigatório!` }]}>
                            <Space.Compact>
                                <Input
                                    style={{ width: '40%' }}
                                    placeholder="XXXX"
                                    value={codigoPostalInicio}
                                    onChange={(e) => handleCodigoPostalChange(e.target.value, 'inicio')}
                                    type="text"
                                    required
                                    maxLength={4}
                                />
                                <Input
                                    style={{ width: '40%' }}
                                    placeholder="XXX"
                                    value={codigoPostalFim}
                                    onChange={(e) => handleCodigoPostalChange(e.target.value, 'fim')}
                                    type="text"
                                    required
                                    maxLength={3}
                                />
                                <Input
                                    style={{ width: '100%' }}
                                    value={codigoPostalCompleto}
                                    readOnly
                                    disabled
                                />
                            </Space.Compact>
                        </Form.Item>
                    </div>
                );
            default:
                return null;
        }
    };


    const renderConditionalFields = () => {
        return Object.keys(conditionalFields).map((key) => {
            return conditionalFields[key].map((field) => renderField(field));
        });
    };

    const handleSubmit = (values) => {
        // console.log('Valores do formulário:', values);
        setLoading(true);
        let formattedData = { ...values };

        if ('data' in values) {
            formattedData = {
                ...formattedData,
                data: formatDate(new Date())
            };
        }

        if ('data_documento' in values) {
            formattedData = {
                ...formattedData,
                data_documento: formatDate(new Date())
            };
        }

        if ('validade_cc' in values) {
            formattedData = {
                ...formattedData,
                validade_cc: formatDate(new Date())
            };
        }

        const dataGerarDocumento = {
            ...formattedData,
            tipo_documento: data.tipoDocumento,
            tipo_sociedade: data.tipoSociedade,
            tipo_contrato: data.tipoContrato,
            user_id: dataUser.iDTokenAuthenticated,
            token: dataUser.saveToken,
            gera_outros_docs: otherDocs
                .filter(doc => selectedDocs.includes(doc.doc.id))  // Filtra os documentos selecionados
                .map(doc => doc.tipo_documento),  // Extrai apenas o tipo_documento dos selecionados
        };

        axios.post(`${process.env.REACT_APP_GERAR_DOCUMENTOS}`, dataGerarDocumento)
            .then((response) => {
                if (response.data.sucesso) {
                    message.success(response.data.mensagem);
                    setData(prev => ({
                        ...prev,
                        linkDownload: response.data.data || {},
                    }));
                } else {
                    message.error(response.data.mensagem);
                }
            })
            .catch(() => {
                message.error('Erro. Tente novamente!');
            })
            .finally(() => setLoading(false));  // Desativa o loading
    };

    // const fieldsArray = Array.isArray(fields) ? fields : Object.values(fields);

    const sortedFields = Array.isArray(fields)
        ? fields.sort((a, b) => a.order - b.order)
        : Object.values(fields).sort((a, b) => a.order - b.order);

    const sortedOtherDocs = Array.isArray(otherDocs)
        ? otherDocs.map(doc => ({
            ...doc,
            doc: {
                ...doc.doc,
                fields: doc.doc.fields.sort((a, b) => a.order - b.order)
            }
        }))
        : otherDocs;

    return (
        <>
            <Form form={form} onFinish={handleSubmit} layout="vertical" autoComplete="off" initialValues={initialValues}>
                <div className={`grid gap-4 ${white ? 'grid-cols-1' : 'grid-cols-3'}`}>
                    {sortedFields.map((field) => renderField(field))}
                    {renderConditionalFields()}
                </div>

                {/* Render the repeater fields separately */}
                {sortedFields.map((field) => field.tipo === 'repeater' && (
                    <div key={field.slug} className="col-span-3">
                        <p className="text-white font-semibold">{field.nome}</p>
                        <Form.List name={field.slug}>
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(({ key, name, ...restField }) => (
                                        <div key={key} className="grid grid-cols-3 gap-2 items-center">
                                            {Object.values(field.opcoes).map((subField) => (
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, subField.slug]}
                                                    rules={[{ required: true, message: `${subField.nome} é obrigatório!` }]}
                                                    key={subField.slug}
                                                    className="col-span-1"
                                                >
                                                    <Input placeholder={subField.nome} />
                                                </Form.Item>
                                            ))}
                                            <DeleteOutlined
                                                onClick={() => remove(name)}
                                                style={{ fontSize: '24px', color: 'red', cursor: 'pointer', marginTop: '-24px' }}
                                            />
                                        </div>
                                    ))}
                                    <Button
                                        type="dashed"
                                        onClick={() => add()}
                                        icon={<PlusOutlined />}
                                        className="w-full mt-2"
                                    >
                                        Adicionar {field.nome}
                                    </Button>
                                </>
                            )}
                        </Form.List>
                    </div>
                ))}

                {/* Render checkboxes for "gera_outros_docs" if otherDocs exists */}
                {Array.isArray(otherDocs) && sortedOtherDocs.length > 0 && (
                    <>
                        <hr className='my-[24px]' />
                        <div className="mb-[24px]">
                            {sortedOtherDocs.map(doc => (
                                <div key={doc.doc.id}>
                                    <Checkbox
                                        onChange={(e) => handleCheckboxChange(doc.doc.id, e.target.checked)}
                                        className='text-white'
                                    >
                                        {doc.doc.nome}
                                    </Checkbox>

                                    {/* Render related fields in sorted order */}
                                    {selectedDocs.includes(doc.doc.id) && (
                                        <div className={`grid gap-4 mt-4 ${white ? 'grid-cols-1' : 'grid-cols-3'}`}>
                                            {doc.doc.fields.map((field) => renderField(field))}
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                        <hr className='mb-[24px]' />
                    </>
                )}

                <Form.Item>
                    <div className="flex items-center gap-4 mt-6">
                        <Button type="primary" htmlType="submit" className='flex gap-2 items-center bg-[#1A8700] text-white font-bold rounded'>
                            <FaFileAlt />
                            Gerar Documento
                        </Button>
                    </div>
                </Form.Item>
            </Form>

            {loading ? (
                <Loading />
            ) : (
                data.linkDownload && Object.keys(data.linkDownload).length > 0 && (
                    <>
                        <hr className='mb-[24px]' />
                        <p className="text-sm font-normal text-white">Documentos gerados com sucesso! Valide todos os campos:</p>
                        <Tabs>
                            {Object.entries(data.linkDownload).map(([key, link]) => (
                                <Tabs.TabPane
                                    tab={<span>{key.charAt(0).toUpperCase() + key.slice(1).toLowerCase()}</span>}
                                    key={key}
                                >
                                    <iframe
                                        src={`https://${link}`}
                                        title={`PDF Viewer - ${key}`}
                                        width="100%"
                                        height="800px"
                                        style={{ border: 'none' }}
                                    ></iframe>
                                </Tabs.TabPane>
                            ))}
                        </Tabs>
                    </>
                )
            )}
        </>
    );
}
