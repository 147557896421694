import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { message } from 'antd';
import InputCalculoProposta from '../../../components/CalculoProposta/InputCalculoProposta';
import TabelaDadosCarroProposta from '../../../components/CalculoProposta/TabelaDadosDoCarro';
import FormCalculoProposta from '../../../components/CalculoProposta/PT/FormCalculoProposta_ISV_IUC';
import TaxaDeServico from '../../../components/CalculoProposta/TaxaDeServicoProposta';
import TaxaDeFinanciamento from '../../../components/CalculoProposta/TaxaDeFinanciamentoProposta';
import PrecoFinalProposta from '../../../components/CalculoProposta/PrecoFinalProposta';
import Loading from '../../../components/Elements/Loading';
import RegistarPropostaForm from '../../../components/CalculoProposta/PT/FormRegistarProposta';
import TaxaDeExpressProposta from '../../../components/CalculoProposta/TaxaDeExpressProposta';


export default function SimulacaoProposta({
    dataUser
}) {
    const [loading, setLoading] = useState(false);
    const [urlCarroLink, setUrlCarroLink] = useState('');
    const [dataCarros, setDataCarros] = useState([]);
    const [linkToken, setLinkToken] = useState('');

    const [propostaInfo, setPropostaInfo] = useState({
        standData: [],
        linkFora: '',
        outros: [],
        erro: '',
    });

    const [isvIucData, setIsvIucData] = useState({
        cilindrada: 0,
        novoUsado: '',
        tipoCombustivel: '',
        emissoesCO2: '',
        hibridoPlugIn: 0,
        wltp: 0,
        particulas: 0,
        dataPrimeiroRegisto: '',
        tipoDeVeiculoParaOutros: 'LigeiroPassageiros',
        tipoDeVeiculoParaInsencoes: '',
        outrosServicos: '',
        valorTotalISV: 0,
        valorTotalIUC: 0
    });

    const [triggerCalculo, setTriggerCalculo] = useState({
        cilindrada: 0,
        novoUsado: '',
        tipoCombustivel: '',
        emissoesCO2: '',
        hibridoPlugIn: 0,
        wltp: 0,
        particulas: 0,
        dataPrimeiroRegisto: '',
        tipoDeVeiculoParaOutros: 'LigeiroPassageiros',
        tipoDeVeiculoParaInsencoes: '',
        outrosServicos: '',
    });



    const [valoresTotais, setValoresTotais] = useState({
        precoViaturaComIva: 0,
        precoViaturaSemIva: 0,
        taxaDeServico: 2800,
        taxaDeFinanciamento: 400,
        taxaDeFinanciamentoAtivo: 0,
        valorExpress: 1000,
        valorExpressAtivo: 0,
        comissao: 0,
        valorTotalISV: 0,
        valorTotalIUC: 0,
        valorPrecoChaveNaMaoParticular: 0,
        valorPrecoChaveNaMaoEmpresa: 0,
        compsIUC: {
            iucCilindrada: 0,
            iucCO2: 0,
            iucAdicionalCO2: 0,
            iucCoeficienteAnoMatriculo: 0,
            iucAdicionalGasoleo: 0
        },
        compsISV: {
            isvcomponentecilindrada: 0,
            isvcomponenteambiental: 0,
            isvReducaoAnosUsoCilindrada: 0,
            isvReducaoAnosUsoAmbiental: 0,
            isvAgravamentoParticulas: 0,
            isvReducaoAnosUsoParticulas: 0,
            isvTaxaAplicavelTabela: '',
        }
    });


    // Função para buscar dados do carro
    const fetchData = useCallback(async (url) => {
        setLoading(true);

        try {
            const requestUrl = `${process.env.REACT_APP_PEDIDO_PROPOSTA}?request=${encodeURIComponent(url)}&user_id=${dataUser.iDTokenAuthenticated}&token=${dataUser.saveToken}&lang=${dataUser.language}`;
            const response = await axios.get(requestUrl);

            if (response.data.sucesso) {

                console.log(response)

                const carro = response.data.data.carro;
                const stand = response.data.data.stand;
                const linkToken = response.data.link_token;
                const calculo = response.data.data.simulacao;


                setDataCarros(carro);
                setLinkToken(linkToken)

                //console.log(urlCarroLink)

                setPropostaInfo((prevData) => ({
                    ...prevData,
                    standData: stand,
                    outros: stand,
                    propostaExpirado: null,
                    propostaDisponivelOnline: null
                }));

                // Verifica o tipo de combustível híbrido e atribui o valor correto
                let tipoCombustivel = calculo.combustivel_para_simulacao;
                if (tipoCombustivel == "Híbrido (Gasolina/Eletrico)") {
                    tipoCombustivel = "Gasolina";
                } else if (tipoCombustivel == "Híbrido (Gasóleo/Eletrico)") {
                    tipoCombustivel = "Gasoleo";
                }

                const allData = {
                    cilindrada: calculo.cilindrada_numerico,
                    novoUsado: calculo.novo == 1 ? "Novo" : "Usado",
                    tipoCombustivel,
                    emissoesCO2: calculo.emissoes_numerico,
                    hibridoPlugIn: calculo.hibrido,
                    wltp: calculo.wltp,
                    particulas: calculo.tipo_combustivel_traduzido == "Gasoleo" ? "1" : "0",
                    dataPrimeiroRegisto: calculo.data_registo_para_simulacao,
                    tipoDeVeiculoParaOutros: calculo.tipo_de_veiculo,
                    tipoDeVeiculoParaInsencoes: '',
                    outrosServicos: '',
                };

                setIsvIucData(allData);  // Atualiza formulário com dados do carro
                setTriggerCalculo(allData);  // Atualiza dados para cálculo

                setValoresTotais((prevData) => ({
                    ...prevData,
                    precoViaturaComIva: carro.preco_final_carro,
                    precoViaturaSemIva: carro.preco_final_carro_sem_iva,
                }));

                setLoading(false);

            } else {
                message.error(response.data.mensagem);
                setUrlCarroLink('');
                setLoading(false);
            }

        } catch (error) {
            setLoading(false);
        }

    }, [dataUser.iDTokenAuthenticated, dataUser.saveToken]);


    useEffect(() => {
        if (urlCarroLink) {
            fetchData(urlCarroLink);
            setPropostaInfo((prevData) => ({
                ...prevData,
                linkFora: urlCarroLink,
            }));

        }
    }, [urlCarroLink, fetchData]);


    // Efeito para disparar o cálculo quando triggerCalculo for atualizado
    useEffect(() => {
        const fetchISVIUCData = async () => {
            try {

                const novo_usado_int = triggerCalculo.novoUsado == "Novo" ? 1 : 0;

                const tipoDeTestes = triggerCalculo.wltp == 1 ? 'WLTP' : 'NEDC';

                let tipoDeCombustivel;
                if (triggerCalculo.tipoCombustivel == "Híbrido (Gasolina/Eletrico)") {
                    tipoDeCombustivel = "Gasolina";
                } else if (triggerCalculo.tipoCombustivel == "Híbrido (Gasóleo/Eletrico)") {
                    tipoDeCombustivel = "Gasoleo";
                } else {
                    tipoDeCombustivel = triggerCalculo.tipoCombustivel;
                }

                let tipoDeVeiculoFinal;
                if (triggerCalculo.hibridoPlugIn == 1) {
                    tipoDeVeiculoFinal = "HibridosPlugIn";
                } else {
                    tipoDeVeiculoFinal = triggerCalculo.tipoDeVeiculoParaOutros;
                }


                const particulas = triggerCalculo.particulas;
                const emissoesGases = triggerCalculo.emissoesCO2;
                const cilindrada = triggerCalculo.cilindrada;
                const dataDeRegisto = triggerCalculo.dataPrimeiroRegisto;
                const tipoDeVeiculoParaInsencoes = triggerCalculo.tipoDeVeiculoParaInsencoes || '';

                // URL do pedido ISV
                const pedidoISV = `${process.env.REACT_APP_PEDIDO_ISV}?novo=${novo_usado_int}&tipo_de_testes_co2=${tipoDeTestes}&tipo_carro=${tipoDeVeiculoFinal}&combustivel=${tipoDeCombustivel}&particulas=${particulas}&co2=${emissoesGases}&cilindrada=${cilindrada}&ano=${dataDeRegisto}&isencao=${tipoDeVeiculoParaInsencoes}&dev`;

                // URL do pedido IUC
                const pedidoIUC = `${process.env.REACT_APP_PEDIDO_IUC}?cilindrada=${cilindrada}&ano=${dataDeRegisto}&combustivel=${tipoDeCombustivel}&co2=${emissoesGases}&tipo_carro=${tipoDeVeiculoFinal}&tipo_de_testes_co2=${tipoDeTestes}&novo=${novo_usado_int}`;

                // Fazer os dois pedidos de uma vez
                const [isvResponse, iucResponse] = await Promise.all([
                    axios.get(pedidoISV),
                    axios.get(pedidoIUC)
                ]);


                const valorISV = isvResponse.data.data.isv;
                const valorIUC = iucResponse.data.data.iuc;
                const valorISVTaxas = isvResponse.data.data.mensagem.taxas;
                const valorIUCTaxas = iucResponse.data.data.mensagem.taxas;

                // Atualizar ambos os estados com os valores calculados
                setIsvIucData((prevData) => ({
                    ...prevData,
                    valorTotalISV: valorISV,
                    valorTotalIUC: valorIUC,
                }));

                setValoresTotais((prevData) => {
                    const novoEstado = {
                        ...prevData,
                        valorTotalISV: valorISV,
                        valorTotalIUC: valorIUC
                    };

                    if (valorIUCTaxas) {
                        novoEstado.compsIUC = {
                            iucCilindrada: valorIUCTaxas.cilindrada,
                            iucCO2: valorIUCTaxas.co2,
                            iucAdicionalCO2: valorIUCTaxas.adicional_co2,
                            iucCoeficienteAnoMatriculo: valorIUCTaxas.coeficiente_ano_matricula,
                            iucAdicionalGasoleo: valorIUCTaxas.adicional_gasoleo
                        };
                    }

                    if (valorISVTaxas) {
                        novoEstado.compsISV = {
                            isvcomponentecilindrada: valorISVTaxas.componente_cilindrada,
                            isvcomponenteambiental: valorISVTaxas.componente_ambiental,
                            isvReducaoAnosUsoCilindrada: valorISVTaxas.reducao_anos_uso_cilindrada,
                            isvReducaoAnosUsoAmbiental: valorISVTaxas.reducao_anos_uso_ambiental,
                            isvAgravamentoParticulas: valorISVTaxas.agravamento_particulas,
                            isvReducaoAnosUsoParticulas: valorISVTaxas.reducao_anos_uso_particulas,
                            isvTaxaAplicavelTabela: valorISVTaxas.taxa_aplicavel_tabela
                        };
                    }

                    return novoEstado;
                });


                if (isvResponse.data.data.mensagem.avisos_isencao.length !== 0) {

                    message.warning(isvResponse.data.data.mensagem.avisos_isencao)

                    setPropostaInfo((prevData) => ({
                        ...prevData,
                        erro: isvResponse.data.data.mensagem.avisos_isencao,
                    }));

                } else {

                    setPropostaInfo((prevData) => ({
                        ...prevData,
                        erro: '',
                    }));

                }

            } catch (error) {
                console.error('Erro ao buscar ISV ou IUC:', error);
            }
        };

        if (
            triggerCalculo.cilindrada &&
            triggerCalculo.novoUsado &&
            triggerCalculo.tipoCombustivel &&
            triggerCalculo.emissoesCO2 &&
            (triggerCalculo.tipoDeVeiculoParaInsencoes || triggerCalculo.tipoDeVeiculoParaOutros)
        ) {
            fetchISVIUCData();
        }

    }, [triggerCalculo]);

    const handleFormChange = (allValues) => {
        setIsvIucData((prevState) => ({
            ...prevState,
            ...allValues
        }));
        setTriggerCalculo((prevState) => ({
            ...prevState,
            ...allValues
        }));
    };


    return (
        <div className='w-full flex flex-col justify-center items-center gap-4'>

            {!urlCarroLink && (
                <div className='card grid-cols-1 w-full h-full'>
                    <InputCalculoProposta
                        urlCarroLink={urlCarroLink}
                        setUrlCarroLink={setUrlCarroLink}
                        dataUser={dataUser}
                    />
                </div>
            )}

            {loading ? (
                <Loading />
            ) : (
                <>
                    {(urlCarroLink && dataCarros) && (
                        <div className="w-full text-white grid gap-4">
                            <div>
                                <div className='card grid gap-4'>
                                    <RegistarPropostaForm
                                        isvIucData={isvIucData}
                                        valoresTotais={valoresTotais}
                                        setUrlCarroLink={setUrlCarroLink}
                                        dataUser={dataUser}
                                        dataCarros={dataCarros}
                                        linkToken={linkToken}
                                    />
                                </div>
                            </div>

                            <div className='flex flex-col sm:flex-row gap-4 w-full h-full'>
                                <div className='w-full sm:w-3/5'>
                                    <TabelaDadosCarroProposta
                                        dataCarros={dataCarros}
                                        isvIucData={isvIucData}
                                        urlCarroLink={urlCarroLink}
                                        dataUser={dataUser}
                                        propostaInfo={propostaInfo}
                                    />
                                </div>
                                <div className="w-full sm:w-2/5 flex flex-col gap-4">
                                    <FormCalculoProposta
                                        propostaInfo={propostaInfo}
                                        isvIucData={isvIucData}
                                        onFormChange={handleFormChange}
                                    />
                                    <TaxaDeServico
                                        valoresTotais={valoresTotais}
                                        setValoresTotais={setValoresTotais}
                                        dataUser={dataUser}
                                    />
                                    <TaxaDeExpressProposta
                                        valoresTotais={valoresTotais}
                                        setValoresTotais={setValoresTotais}
                                        dataUser={dataUser}
                                    />
                                    <TaxaDeFinanciamento
                                        valoresTotais={valoresTotais}
                                        setValoresTotais={setValoresTotais}
                                    />
                                    <PrecoFinalProposta
                                        valoresTotais={valoresTotais}
                                        setValoresTotais={setValoresTotais}
                                        dataUser={dataUser}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
}
