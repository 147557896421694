import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export default function PrecoChaveNaMaoProposta({ valoresTotais, setValoresTotais, activeTabKey, formatarNumero }) {

    const { t } = useTranslation();

    const [valorFinal, setValorFinal] = useState(0);

    useEffect(() => {
        // Verifica se os valores necessários estão definidos e os converte para números
        const precoViaturaComIva = parseFloat(valoresTotais.precoViaturaComIva) || 0;
        const precoViaturaSemIva = parseFloat(valoresTotais.precoViaturaSemIva) || 0;
        const taxaDeServico = parseFloat(valoresTotais.taxaDeServico) || 0;
        const valorTotalISV = parseFloat(valoresTotais.valorTotalISV) || 0;
        const taxaDeFinanciamento = parseFloat(valoresTotais.taxaDeFinanciamento) || 0;
        const taxaExpress = parseFloat(valoresTotais.valorExpress) || 0;
        const iuc = parseFloat(valoresTotais.iuc) || 0;

        // Função para calcular o valor total
        const calcularValorTotal = (precoViatura) => {
            let valorTotal = precoViatura + taxaDeServico + valorTotalISV;
            // Adiciona o valor de financiamento se estiver ativo
            if (valoresTotais.taxaDeFinanciamentoAtivo) {
                valorTotal += taxaDeFinanciamento;
            }
            if (valoresTotais.valorExpressAtivo) {
                valorTotal += taxaExpress;
            }
            // Adiciona o valor do IUC se presente
            valorTotal += iuc;
            return valorTotal;
        };

        // Calcula o valor para particular e empresa
        const valorTotalParticular = calcularValorTotal(precoViaturaComIva);
        const valorTotalEmpresa = calcularValorTotal(precoViaturaSemIva);

        // console.log(valorTotalParticular)
        // console.log(valorTotalEmpresa)

        // Atualiza o estado com os valores calculados para particular e empresa
        setValoresTotais((prevState) => ({
            ...prevState,
            valorPrecoChaveNaMaoParticular: valorTotalParticular,
            valorPrecoChaveNaMaoEmpresa: valorTotalEmpresa,
        }));

        // Define o valor final com base na aba ativa (1 = Particular, 2 = Empresa)
        const valorTotalFinal = activeTabKey === "1" ? valorTotalParticular : valorTotalEmpresa;

        // Garante que o valor total final é sempre um número válido
        setValorFinal(Number.isNaN(valorTotalFinal) ? 0 : valorTotalFinal);


    }, [
        valoresTotais.precoViaturaComIva,
        valoresTotais.precoViaturaSemIva,
        valoresTotais.taxaDeServico,
        valoresTotais.valorTotalISV,
        valoresTotais.taxaDeFinanciamentoAtivo,
        valoresTotais.taxaDeFinanciamento,
        valoresTotais.valorExpressAtivo,
        valoresTotais.valorExpress,
        valoresTotais.iuc,
        activeTabKey,
        setValoresTotais
    ]);

    return (
        <div className='flex justify-between items-center'>
            <p className='text-black text-base font-semibold'>{t('tables.preco_chave_da_mao')}</p>
            <p className='font-bold text-lg'>
                {/* {typeof valorFinal === 'number' && !isNaN(valorFinal) ? valorFinal.toFixed(2) : '0.00'} € */}
                {formatarNumero(valorFinal)}
            </p>
        </div>
    );
}
