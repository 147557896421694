import React, { useState } from 'react';
import { Button, Input, message, Form } from 'antd';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

export default function FromEditarProposta({
    dataUser,
    propostaInfo,
    valoresTotais,
    setValoresTotais,
    isvIucData
}) {

    const { t } = useTranslation();

    const [isvCalculado, setIsvCalculado] = useState(valoresTotais.valorTotalISV); // Estado local para armazenar o resultado do cálculo do ISV
    const [usouCalculo, setUsouCalculo] = useState(false);
    const [timeoutId, setTimeoutId] = useState(null);
    const [isCalculating, setIsCalculating] = useState(false); // Novo estado


    // Função para alterar os valores de todos os inputs
    const handleValueChange = (e) => {
        const { name, value } = e.target;
        setValoresTotais((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };


    // console.log('valoresTotais', valoresTotais)

    // Função para calcular o ISV baseado nas emissões de CO2
    const handleNovasEmissoes = (e) => {
        setUsouCalculo(false);
        setIsCalculating(true); // Inicia o cálculo

        const novoValorEmissoes = e.target.value;

        if (timeoutId) {
            clearTimeout(timeoutId);
        }

        if (novoValorEmissoes) {
            const newTimeoutId = setTimeout(() => {
                const data = {
                    id_proposta: propostaInfo.idProposta,
                    novo: isvIucData.novoUsado,
                    tipo_de_testes_co2: isvIucData.wltp,
                    tipo_carro: isvIucData.tipoDeVeiculoParaOutros,
                    combustivel: isvIucData.tipoCombustivel,
                    particulas: isvIucData.tipoCombustivel,
                    co2: novoValorEmissoes,
                    cilindrada: isvIucData.cilindrada,
                    ano: isvIucData.dataPrimeiroRegisto,
                    isencao: isvIucData.tipoDeVeiculoParaInsencoes,
                    user_id: dataUser.iDTokenAuthenticated,
                    token: dataUser.saveToken,
                };
                
                let pedido
               
                if(dataUser.language === 'pt'){
                    pedido = process.env.REACT_APP_PEDIDO_ISV
                } else {
                    pedido = process.env.REACT_APP_PEDIDO_ISV_ES
                }

                axios.post(pedido, data)
                    .then((response) => {
                        if (response.data.sucesso) {
                            setIsvCalculado(response.data.data.isv);
                            setValoresTotais((prevData) => ({
                                ...prevData,
                                valorTotalISV: response.data.data.isv
                            }));
                            setUsouCalculo(true);
                        } else {
                            message.error(response.data.mensagem);
                        }
                    })
                    .catch(() => {
                        message.error(t('editar_proposta.error'));
                    })
                    .finally(() => {
                        setIsCalculating(false); // Finaliza o cálculo
                    });

            }, 1000);

            setTimeoutId(newTimeoutId);
        } else {
            message.error(t('editar_proposta.error'));
            setIsCalculating(false); // Finaliza o cálculo mesmo em caso de erro
        }
    };

    // console.log(valoresTotais)

    // Função para finalizar a edição da proposta
    const finalizarEdiçãodeProposta = (values) => {

        // console.log(isvCalculado)

        let dados = {
            emissoes_numerico: values.emissoesCO2, // Pega o valor atualizado
            taxadeservico: values.taxaDeServico,
            valor_chavenamao_particular: values.precoViaturaComIva,
            valor_chavenamao_empresa: values.precoViaturaSemIva,
            isv: isvCalculado,
            isv_componente_cilindrada: valoresTotais.compsISV.isvcomponentecilindrada,
            isv_componente_ambiental: valoresTotais.compsISV.isvcomponenteambiental,
            isv_reducao_anos_uso_cilindrada: valoresTotais.compsISV.isvReducaoAnosUsoCilindrada,
            isv_reducao_anos_uso_ambiental: valoresTotais.compsISV.isvReducaoAnosUsoAmbiental,
            isv_agravamento_particulas: valoresTotais.compsISV.isvAgravamentoParticulas,
            isv_reducao_anos_uso_particulas: valoresTotais.compsISV.isvReducaoAnosUsoParticulas,
            isv_taxa_aplicavel_tabela: valoresTotais.compsISV.isvTaxaAplicavelTabela,
        };


        const data = {
            id_proposta: propostaInfo.idProposta,
            user_id: dataUser.iDTokenAuthenticated,
            token: dataUser.saveToken,
            array_dados: dados,
        };



        axios.post(`${process.env.REACT_APP_URL_EDITAR_PROPOSTA}`, data)
            .then((response) => {
                // console.log(response)
                if (response.data.sucesso) {
                    message.success(response.data.mensagem);
                    // console.log(response)
                } else {
                    message.error(response.data.mensagem);
                }
            })
            .catch(() => {
                message.error(t('editar_proposta.error'));
            });
    };


    return (
        <Form
            layout="vertical"
            onFinish={finalizarEdiçãodeProposta}
            initialValues={{
                taxaDeServico: valoresTotais.taxaDeServico,
                precoViaturaComIva: valoresTotais.precoViaturaComIva,
                precoViaturaSemIva: valoresTotais.precoViaturaSemIva,
                emissoesCO2: isvIucData.emissoesCO2,
            }}
        >

            <div className='flex flex-col items-stretch gap-2'>
                <p className='font-semibold'>{t('editar_proposta.taxa_servico')}</p>
                <Form.Item name="taxaDeServico">
                    <Input
                        placeholder={t('editar_proposta.taxa_servico')}
                        value={valoresTotais.taxaDeServico}
                        onChange={handleValueChange}
                        name="taxaDeServico"
                        type="number"
                    />
                </Form.Item>
            </div>

            <div className='flex flex-col items-stretch gap-2'>
                <p className='font-semibold'>{t('editar_proposta.valor_v_particular')}</p>
                <Form.Item name="precoViaturaComIva">
                    <Input
                        placeholder={t('editar_proposta.valor_v_particular')}
                        value={valoresTotais.precoViaturaComIva}
                        onChange={handleValueChange}
                        name="precoViaturaComIva"
                        type="number"
                    />
                </Form.Item>
            </div>


            <div className='flex flex-col items-stretch gap-2'>
                <p className='font-semibold'>{t('editar_proposta.valor_v_empresa')}</p>
                <Form.Item name="precoViaturaSemIva">
                    <Input
                        placeholder={t('editar_proposta.valor_v_empresa')}
                        value={valoresTotais.precoViaturaSemIva}
                        onChange={handleValueChange}
                        name="precoViaturaSemIva"
                        type="number"
                    />
                </Form.Item>
            </div>

            <div className='flex flex-col items-stretch gap-2'>
                <p className='font-semibold'>{t('editar_proposta.emissoes')}</p>
                <Form.Item name="emissoesCO2">
                    <Input
                        placeholder={t('editar_proposta.emissoes')}
                        value={isvIucData.emissoesCO2} // Valor atual de emissoes CO2
                        onChange={handleNovasEmissoes} // Calcula o ISV no onChange
                        name="emissoesCO2"
                        type="number"
                    />
                    {/* {isvCalculado && <p>ISV: {isvCalculado} €</p>} */}
                </Form.Item>
            </div>
            {usouCalculo && (
                <div className='flex flex-col items-stretch gap-2 mb-6'>
                    <p className='font-semibold'>{t('tables.isv')}</p>
                    <Input
                        placeholder={t('tables.isv')}
                        value={isvCalculado} // Valor atual de emissoes CO2
                        type="number"
                    />
                </div>
            )}
            <Form.Item>
                {!isCalculating && ( // Mostra o botão apenas se não estiver calculando
                    <Button type="primary" htmlType="submit" className="shadow-none font-bold text-black bg-green-400">
                        {t('editar_proposta.guardar')}
                    </Button>
                )}
            </Form.Item>
        </Form>
    );
}
