import React from 'react';
import {
    Routes,
    Route
} from "react-router-dom";
import { useTranslation } from 'react-i18next';
import NotFound from './pages/NotFound';
import Dashboard from './pages/Dashboard/Dashboard';
import Login from './pages/Login/Login';
import HistoricoPropostas from './pages/HistoricoPropostas/HistoricoPropostas';
import PerfilContactoHubspot from './pages/Hubspot/PerfilContactoHubspot';
import SimulacaoProposta from './pages/Propostas/PT/SimulacaoProposta';
import TodasAsPropostas from './pages/Propostas/TodasPropostas';
import TodasAutomacoes from './pages/Automacoes/TodasAutomacoes';
import PerfilDealHubspot from './pages/Hubspot/PerfilDealHubspot';
import PerfilDaPropostaPT from './pages/Propostas/PT/PerfilPropostaPT';
import LogsDevs from './pages/Logs/LogsDevs';
import Notificacoes from './pages/Notificacoes/Notificacoes';
import GerarAssinaturaEmail from './pages/Ferramentas/GerarAssinaturaEmail';
import ListaDeUtilizadores from './pages/Users/ListaDeUtilizadores';
import SimulacionPropuesta from './pages/Propostas/ES/SimulacionPropuesta';
import PerfilPropuestaES from './pages/Propostas/ES/PerfilPropuestaES';
import SimuladorDeCredito from './pages/Ferramentas/SimuladorDeCredito';
import GerarDocumentos from './pages/Ferramentas/GerarDocumentos';
import SimuladorISV from './pages/Ferramentas/SimuladorISV';
import VerDocumentos from './pages/Ferramentas/VerDocumentos';
import GerarContratoProcuracao from './components/pt/gerador_de_documentos/gerar-contratos-procuracao';


export function AppRoutes({
    dataUser,
    notificacaoState
}) {

    const { t } = useTranslation();


    return (
        <Routes>
            {dataUser.isAuthenticated && (
                <>
                    {/* Dashboard */}
                    <Route
                        path="/"
                        element={<Dashboard dataUser={dataUser} />}
                    />

                    {/* SimulacaoProposta - PT */}
                    <Route
                        path={`/gerar-propostas`.toLowerCase()
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '')  // Remove accents
                            .replace(/ /g, '-')}  // Replace spaces with dashes
                        element={<SimulacaoProposta dataUser={dataUser} />}
                    />

                    <Route
                        path={`/simulador-isv`.toLowerCase()
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '')  // Remove accents
                            .replace(/ /g, '-')}  // Replace spaces with dashes
                        element={<SimuladorISV dataUser={dataUser} />}
                    />

                    {/* SimulacaoProposta - ES */}
                    <Route
                        path={`/generar-propuestas`.toLowerCase()
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '')  // Remove accents
                            .replace(/ /g, '-')}  // Replace spaces with dashes
                        element={<SimulacionPropuesta dataUser={dataUser} />}
                    />

                    {/* PerfilDaProposta - PT */}
                    <Route
                        path={`/Proposta`.toLowerCase()
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '')  // Remove accents
                            .replace(/ /g, '-')}  // Replace spaces with dashes
                        element={<PerfilDaPropostaPT dataUser={dataUser} />}
                    />

                    {/* PerfilDaProposta - ES */}
                    <Route
                        path={`/Propuesta`.toLowerCase()
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '')  // Remove accents
                            .replace(/ /g, '-')}  // Replace spaces with dashes
                        element={<PerfilPropuestaES dataUser={dataUser} />}
                    />

                    {/* VerPropostas */}
                    <Route
                        path={`/${t('menu.ver_propostas')
                            .toLowerCase()
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '')  // Remove accents
                            .replace(/ /g, '-')}`}  // Replace spaces with dashes
                        element={<TodasAsPropostas dataUser={dataUser} />}
                    />

                    {/* HistoricoPropostas */}
                    <Route
                        path={`/${t('menu.historico')
                            .toLowerCase()
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '')  // Remove accents
                            .replace(/ /g, '-')}`}  // Replace spaces with dashes
                        element={<HistoricoPropostas dataUser={dataUser} />}
                    />

                    {/* Hubspot */}
                    {/* Hubspot - PerfilContactoHubspot */}
                    <Route
                        path={`/${t('menu.hubspot')
                            .toLowerCase()
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '')  // Remove accents
                            .replace(/ /g, '-')}`}  // Replace spaces with dashes
                        element={<PerfilContactoHubspot dataUser={dataUser} />}
                    />
                    {/* Hubspot - PerfilDealHubspot */}
                    <Route
                        path={`/${t('menu.hubspot')
                            .toLowerCase()
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '')  // Remove acentos
                            .replace(/ /g, '-')}/${t('menu.perfil-deal')
                                .toLowerCase()
                                .normalize('NFD')
                                .replace(/[\u0300-\u036f']/g, '')  // Remove acentos
                                .replace(/ /g, '-')}`}  // Substitui espaços por hífens
                        element={<PerfilDealHubspot dataUser={dataUser} />}
                    />

                    {/* Automacoes */}
                    <Route
                        path={`/${t('menu.automacoes')
                            .toLowerCase()
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '')  // Remove accents
                            .replace(/ /g, '-')}`}  // Replace spaces with dashes
                        element={<TodasAutomacoes dataUser={dataUser} />}
                    />

                    {/* Logs Devs */}
                    <Route
                        path={`/logs-devs`}
                        element={<LogsDevs dataUser={dataUser} />}
                    />

                    <Route
                        path={`/${t('menu.notificacoes')
                            .toLowerCase()
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '')  // Remove accents
                            .replace(/ /g, '-')}`}  // Replace spaces with dashes
                        element={<Notificacoes dataUser={dataUser} notificacaoState={notificacaoState} />}
                    />

                    <Route
                        path={`/${t('menu.gerar_assinatura')
                            .toLowerCase()
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '')  // Remove accents
                            .replace(/ /g, '-')}`}  // Replace spaces with dashes
                        element={<GerarAssinaturaEmail dataUser={dataUser} />}
                    />

                    <Route
                        path={`/${t('menu.gerar_documentos')
                            .toLowerCase()
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '')  // Remove accents
                            .replace(/ /g, '-')}`}  // Replace spaces with dashes
                        element={<GerarDocumentos dataUser={dataUser} />}
                    />

                    <Route
                        path={`/${t('menu.ver_documentos')
                            .toLowerCase()
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '')  // Remove accents
                            .replace(/ /g, '-')}`}  // Replace spaces with dashes
                        element={<VerDocumentos dataUser={dataUser} />}
                    />

                    <Route
                        path={`/${t('menu.simulador_credito')
                            .toLowerCase()
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '')  // Remove accents
                            .replace(/ /g, '-')}`}  // Replace spaces with dashes
                        element={<SimuladorDeCredito dataUser={dataUser} />}
                    />

                    <Route
                        path={`/${t('menu.lista_utilizadores')
                            .toLowerCase()
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '')  // Remove accents
                            .replace(/ /g, '-')}`}  // Replace spaces with dashes
                        element={<ListaDeUtilizadores dataUser={dataUser} />}
                    />

                    <Route path='/gerador-documentos-old' element={
                        <GerarContratoProcuracao
                            dataUser={dataUser}
                        />
                    } />

                </>
            )}

            {!dataUser && (
                <Route path="/" element={<div className='h-screen p-2 md:p-10 content-center'><Login /></div>} />
            )}

            <Route path="*" element={<div className='alinhar-centro'><NotFound /></div>} />

        </Routes>
    )
}
