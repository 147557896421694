import axios from 'axios';
import { FaCheckCircle, FaClock, FaRegFile } from 'react-icons/fa';
import { BsFiletypePdf, BsFiletypeJpg, BsFiletypeSvg, BsFiletypeTxt, BsFiletypePng } from 'react-icons/bs';
import { IoMdCloseCircle } from 'react-icons/io';
import { HiOutlineDownload, HiDotsVertical } from 'react-icons/hi';
import { Button, Popover, message } from 'antd';

export default function VerDocumentacaoPerfilDeal({ data, setData, dataUser }) {

    const handleDownloadFicheiros = async (fileNames) => {
        // Se fileNames for uma string, transforma-o em um array com um único elemento
        if (typeof fileNames === 'string') {
            fileNames = [fileNames];
        }
    
        // console.log(fileNames);
    
        try {
            for (const fileName of fileNames) {
                // console.log(fileName);
    
                const response = await axios.get(`${process.env.REACT_APP_DOWNLOAD_DOS_FICHEIROS}`, {
                    params: {
                        token: dataUser.saveToken,
                        user_id: dataUser.iDTokenAuthenticated,
                        nome_ficheiro: fileName,
                        id_deal: data.idDeal,
                        tipo: 'documentacao_deal'
                    },
                    responseType: 'blob' // Para lidar com o download de ficheiros
                });

                // console.log(response)
    
                // Criar um link temporário para fazer o download
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName); // Definir o nome do ficheiro para o download
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link); // Remover o link do DOM
                window.URL.revokeObjectURL(url); // Liberar o objeto URL
                message.success('Download do ficheiro com sucesso!');
            }
        } catch (error) {
            console.error('Erro ao fazer download do ficheiro:', error);
        }
    };
    


    const getStatusIcon = (nomeDocumento) => {
        const status = data.documentosData.estado_documentos.find(doc => doc.nome === nomeDocumento)?.status;
        switch (status) {
            case 'accepted':
                return <FaCheckCircle style={{ fontSize: '18px', color: '#0D9F57' }} />;
            case 'rejected':
                return <IoMdCloseCircle style={{ fontSize: '18px', color: '#FF0000' }} />;
            case 'validation':
                return <FaClock style={{ fontSize: '18px', color: '#eab308' }} />;
            default:
                return null;
        }
    };

    const iconTypeFicheiro = (fileType) => {
        switch (fileType) {
            case 'pdf':
                return <BsFiletypePdf style={{ fontSize: '35px', color: '#E62335' }} />;
            case 'jpg':
            case 'jpeg':
                return <BsFiletypeJpg style={{ fontSize: '35px', color: '#EAB308' }} />;
            case 'svg':
                return <BsFiletypeSvg style={{ fontSize: '35px', color: '#1F77B4' }} />;
            case 'png':
                return <BsFiletypePng style={{ fontSize: '35px', color: '#00CC69' }} />;
            case 'txt':
                return <BsFiletypeTxt style={{ fontSize: '35px', color: '#00BFFF' }} />;
            default:
                return <FaRegFile style={{ fontSize: '35px', color: '#C0C0C0' }} />;
        }
    };

    const DocumentoItem = ({ titulo, fileName, statusName, onDownload, fileType }) => (
        <div className='bg-black text-white border-card py-4 px-3.5 flex items-center justify-between gap-2 transition-all'>
            <div className='flex items-center gap-2 text-white'>
                {iconTypeFicheiro(fileType)}
                <div className='flex items-center gap-2'>
                    <p className='text-sm font-semibold'>{titulo}</p>
                    {getStatusIcon(statusName)}
                </div>
            </div>
            <Popover placement="bottomRight"
                content={
                    <Button className='flex items-center shadow-none font-bold text-black bg-white' icon={<HiOutlineDownload />} onClick={onDownload}>
                        Download
                    </Button>
                }>
                <HiDotsVertical className="cursor-pointer" />
            </Popover>
        </div>
    );

    // if (process.env.NODE_ENV === 'development') {
    //     console.log(data?.documentosData?.ultimos_documentos_enviados);
    // }
    // console.log(data?.documentosData?.ultimos_documentos_enviados?.ficheiros?.documento_identificacao[0].nome)

    return (
        <>
            <div className='grid grid-cols-1 xs:grid-cols-2 lg:grid-cols-3 my-4 gap-4 lg:gap-6'>
                {/* {data?.documentosData?.ultimos_documentos_enviados?.certidao_permanente && (
                    <DocumentoItem
                        titulo="Certidão Permanente"
                        fileName={data?.documentosData?.ultimos_documentos_enviados?.certidao_permanente}
                        statusName="Certidão Permanente"
                        fileType={'txt'}
                        onDownload={false}
                    />
                )} */}

                {data?.documentosData?.ultimos_documentos_enviados?.ficheiros?.documento_identificacao && (
                    <DocumentoItem
                        titulo="Documento de Identificação"
                        fileName={data?.documentosData?.ultimos_documentos_enviados?.ficheiros?.documento_identificacao}
                        statusName="Documento de Identificação"
                        fileType={data?.documentosData?.ultimos_documentos_enviados?.ficheiros?.documento_identificacao[0]?.type}
                        onDownload={() => handleDownloadFicheiros(data?.documentosData?.ultimos_documentos_enviados?.ficheiros?.documento_identificacao[0].nome)}
                    />
                )}

                {data?.documentosData?.ultimos_documentos_enviados?.ficheiros?.documento_conducao && (
                    <DocumentoItem
                        titulo="Carta de Condução"
                        fileName={data?.documentosData?.ultimos_documentos_enviados?.ficheiros?.documento_conducao}
                        statusName="Carta de Condução"
                        fileType={data?.documentosData?.ultimos_documentos_enviados?.ficheiros?.documento_conducao[0]?.type}
                        onDownload={() => handleDownloadFicheiros(data?.documentosData?.ultimos_documentos_enviados?.ficheiros?.documento_conducao[0].nome)}
                    />
                )}

                {data?.documentosData.info?.tipo_contrato === "particular" && data?.documentosData?.ultimos_documentos_enviados?.documento_morada && (
                    <div className='bg-black text-white border-card py-4 px-3.5 flex items-center justify-between gap-2 transition-all'>
                        <div className='flex items-center gap-2 text-white'>
                            {iconTypeFicheiro("txt")}
                            <div className='flex items-center gap-2'>
                                <p className='text-sm font-semibold'>Morada</p>
                                {getStatusIcon('Morada')}
                            </div>
                        </div>
                        <span>{data?.documentosData?.ultimos_documentos_enviados?.documento_morada}</span>
                    </div>
                )}


                {data?.documentosData.info?.tipo_contrato === "empresa" && data?.documentosData?.ultimos_documentos_enviados?.certidao_permanente && (
                    <div className='bg-black text-white border-card py-4 px-3.5 flex items-center justify-between gap-2 transition-all'>
                        <div className='flex items-center gap-2 text-white'>
                            {iconTypeFicheiro("txt")}
                            <div className='flex items-center gap-2'>
                                <p className='text-sm font-semibold'>Certidão Permanente</p>
                                {getStatusIcon('Certidão Permanente')}
                            </div>
                        </div>
                        <span>{data?.documentosData?.ultimos_documentos_enviados?.certidao_permanente}</span>
                    </div>
                )}
            </div>
        </>
    );
}
