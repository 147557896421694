import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { HiRefresh } from 'react-icons/hi';
import { FaCheckCircle, FaHubspot } from 'react-icons/fa';
import TableAnalyticsUsers from '../../components/Dashboard/TableAnalyticsUsers';
import CardDestaque from '../../components/AllCards/CardDestaque';
import CardDestaqueEmailsFalhados from '../../components/Dashboard/CardDestaqueEmailsFalhados';
import { Select, DatePicker, message } from 'antd';
import ChartPropostas from '../../components/Charts/ChartsPropostas';
import flagPortugal from '../../assets/flags/flag-portugal.svg';
import flagSpain from '../../assets/flags/flag-spain.svg';
import Loading from '../../components/Elements/Loading';

const { RangePicker } = DatePicker;

export default function Dashboard({ dataUser }) {

    const { t } = useTranslation();

    const [filtersSelected, setFiltersSelected] = useState([]); //Filtros selecionados
    const [loading, setLoading] = useState([]);
    const [data, setData] = useState({
        propostas: [],
        analytics: [],
        destaques: [],
        charts: [],
    });

    const [dateRange, setDateRange] = useState([null, null]); // Estado separado para dateRange

    const [filter, setFilter] = useState({
        dia: [],
        mes: [],
        dateRange: [null, null],
        ano: [],
        user: [],
        dataQuadro: 'today',
        labelDataQuadro: t('tempo.hoje')
    });

    useEffect(() => {


        const fetchData = async () => {
            setLoading(true)

            let url = `${process.env.REACT_APP_GET_ANALYTICS_TABELA}?token=${dataUser.saveToken}&user_id=${dataUser.iDTokenAuthenticated}&lang=${dataUser.language}&mes=${filter.dataQuadro}`;

            const { id_criou, paisPropostas, dataCriacaoInicio, dataCriacaoFim } = filtersSelected;

            if (id_criou !== undefined) url += `&id_criou=${id_criou.toString()}`; // Se necessário, converta para string
            if (paisPropostas !== undefined) url += `&pais=${paisPropostas}`;
            if (dataCriacaoInicio) url += `&data_inicio=${dataCriacaoInicio}`;
            if (dataCriacaoFim) url += `&data_fim=${dataCriacaoFim}`;

            try {
                const response = await axios.get(url);
                console.log(response)
                if (response.data.sucesso) {
                    setData(prev => ({
                        ...prev,
                        propostas: response.data.data.user_data,
                        analytics: response.data.data.automacoes,
                        destaques: response.data.data.destaques,
                    }));
                } else {
                    message.warning(response.data.mensagem)
                }



                // console.log(response.data.data)
                setLoading(false)

            } catch (error) {
                console.error('Error occurred:', error);
            }
        };

        fetchData();

    }, [dataUser.iDTokenAuthenticated, filtersSelected, filter.dataQuadro]);


    useEffect(() => {


        const fetchData = async () => {

            let url = `${process.env.REACT_APP_GET_ANALYTICS_TABELA_PARA_PESQUISAR_DATA_SEMPRE}?token=${dataUser.saveToken}&user_id=${dataUser.iDTokenAuthenticated}&lang=${dataUser.language}&propostas_por_mes=1`;

            try {
                const response = await axios.get(url);
              
                if (response.data.sucesso) {
                    setData(prev => ({
                        ...prev,
                        grafico_propostas: response.data.data.propostas_por_mes
                    }));
                } else {
                    message.warning(response.data.mensagem)
                }

            } catch (error) {
                console.error('Error occurred:', error);
            }
        };

        fetchData();

    }, [dataUser.iDTokenAuthenticated, filtersSelected, filter.dataQuadro]);



    const handleDateChange = (dates, dateStrings) => {
        setDateRange(dates);
        setFiltersSelected(prev => ({
            ...prev,
            dataCriacaoInicio: dateStrings[0] || null,
            dataCriacaoFim: dateStrings[1] || null,
        }));
    };

    return (
        <>

            <div className='flex gap-2 items-center'>
                <span className='text-sm text-gray-300'>{t('dashboard.filtrar_por')}:</span>
                <Select
                    placeholder={'Selecione o período'}
                    className="select-filters capitalize"
                    defaultValue={filter.dataQuadro}
                    onSelect={(value, option) => {
                        setFilter({
                            ...filter,
                            dataQuadro: value,
                            labelDataQuadro: option.children
                        });

                        // Limpa o dateRange quando um filtro predefinido é selecionado
                        if (value !== "personalizado") {
                            setDateRange([null, null]);
                            setFiltersSelected(prev => ({
                                ...prev,
                                dataCriacaoInicio: null,
                                dataCriacaoFim: null,
                            }));
                        }
                    }}
                    popupMatchSelectWidth={false}
                >
                    <Select.Option key="today" value="today" className="capitalize">
                        {t('tempo.hoje')}
                    </Select.Option>
                    <Select.Option key="this_week" value="this_week" className="capitalize">
                        {t('tempo.esta_semana')}
                    </Select.Option>
                    <Select.Option key="this_month" value="this_month" className="capitalize">
                        {t('tempo.este_mes')}
                    </Select.Option>
                    <Select.Option key="last_month" value="last_month" className="capitalize">
                        {t('tempo.mes_passado')}
                    </Select.Option>
                    <Select.Option key="last_three_months" value="last_three_months" className="capitalize">
                        {t('tempo.ultimos_3_meses')}
                    </Select.Option>
                    <Select.Option key="all" value="all" className="capitalize">
                        {t('tempo.sempre')}
                    </Select.Option>
                    <Select.Option key="personalizado" value="personalizado" className="capitalize">
                        {t('tempo.personalizado')}
                    </Select.Option>
                </Select>
                {filter.dataQuadro == "personalizado" && (

                    <RangePicker
                        value={dateRange}
                        onChange={handleDateChange}
                        placeholder={['Data de início', 'Data de fim']}
                    />

                )}
            </div>


            <div className="grid grid-cols-4 gap-4 w-full">
                <div className={`col-span-4 ... `}>
                    <div className={`grid ${dataUser.language === 'pt' ? 'grid-cols-4' : 'grid-cols-3'} gap-4`}>
                        <CardDestaque
                            text={t('dashboard.propostas_criadas_hoje')}
                            number={data.destaques?.atual?.propostas_geradas}
                            comparadorNumero={data.destaques?.comparacao?.propostas_geradas_numero}
                            comparadorPercentagem={data.destaques?.comparacao?.propostas_geradas_percentagem}
                            icon={<HiRefresh size={20} />}
                            style={false}
                            labelDataQuadro={filter.labelDataQuadro}
                        />
                        <CardDestaque
                            text={t('dashboard.propostas_associadas_hoje')}
                            number={data.destaques?.atual?.propostas_associadas}
                            comparadorNumero={data.destaques?.comparacao?.propostas_associadas_numero}
                            comparadorPercentagem={data.destaques.comparacao?.propostas_associadas_percentagem}
                            icon={<FaHubspot size={20} />}
                            style={false}
                            labelDataQuadro={filter.labelDataQuadro}
                        />

                        {dataUser.language === 'pt' && (
                            <CardDestaque
                                text={t('dashboard.propostas_aceites_hoje')}
                                number={data.destaques?.atual?.propostas_aceites}
                                comparadorNumero={data.destaques?.comparacao?.propostas_aceites_numero}
                                comparadorPercentagem={data.destaques?.comparacao?.propostas_aceites_percentagem}
                                icon={<FaCheckCircle size={20} />}
                                style={false}
                                labelDataQuadro={filter.labelDataQuadro}
                            />
                        )}

                        <CardDestaqueEmailsFalhados
                            dataUser={dataUser}
                            labelDataQuadro={filter.labelDataQuadro}
                        />
                    </div>
                </div>
                <div className="col-span-3 ... card">
                    <div className='grid gap-4'>
                        <TableAnalyticsUsers
                            data={data.propostas}
                            dataUser={dataUser}
                            filtersSelected={filtersSelected}
                            setFiltersSelected={setFiltersSelected}
                            loading={loading}
                        />
                    </div>
                </div>
                <div className={`col-span-1 flex flex-col gap-4 overflow-auto h-full max-h-[800px] ${dataUser.role === 'administrator' ? 'pr-4' : ''}`}>
                    <div className='card grid gap-4'>
                        <div className='flex justify-between text-white'>
                            <p className='text-sm font-medium'>{t('dashboard.analy_auto')} <span className='lowercase'>{filter.labelDataQuadro}</span></p>
                        </div>
                    </div>


                    {!data.analytics || Object.keys(data.analytics).length === 0 ? ( // Verifica se `data.analytics` é undefined ou vazio
                        <Loading />
                    ) : (
                        (dataUser.role === 'administrator'
                            ? ['pt', 'es'] // Se for administrador, mostra os dois idiomas
                            : [dataUser.language] // Caso contrário, mostra apenas o idioma atual
                        ).map(language => (
                            data.analytics.atual?.[language]?.map(item => {
                                return (
                                    <CardDestaque
                                        key={`${language}-${item.slug}`} // Adiciona uma key única para cada item do mapeamento
                                        text={item.label} // Usa o label do item
                                        number={item.number} // O número vem do valor correspondente ao item
                                        comparadorNumero={data.analytics.comparacoes?.[language]?.[`${item.slug}_numero`]} // Acessa dinamicamente a comparação do número
                                        comparadorPercentagem={data.analytics.comparacoes?.[language]?.[`${item.slug}_percentagem`]} // Acessa dinamicamente a comparação de percentagem
                                        icon={language === 'pt' ? flagPortugal : flagSpain} // Escolha o ícone com base no idioma
                                        style={true}
                                        automacao={true}
                                    />
                                );
                            })
                        ))
                    )}


                </div>
                {Object.keys(data.analytics).length > 0 && (
                    <div className="col-span-4 card grid gap-6">
                        <div className='flex gap-2 items-center'>
                            <p className='text-white text-sm font-medium pb-4'>{t('dashboard.totalpropostasgeradas')}</p>
                        </div>
                        <ChartPropostas data={data.grafico_propostas} />
                    </div>
                )}
            </div>
        </>
    );
}
