import React, { useRef, useEffect } from 'react';
import { Input, Tooltip, message } from 'antd';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';

const TaxaDeServico = ({ valoresTotais, setValoresTotais, dataUser }) => {

    const { t } = useTranslation();

    const timeoutRef = useRef(null);

    // Função para exibir uma mensagem de aviso
    const warning = () => {
        message.warning('A taxa de serviço está abaixo do mínimo recomendado.');
    };

    // Função que lida com mudanças no valor da taxa de serviço
    const handleTaxaDeServicoChange = (e) => {
        const value = e.target.value;

        // Calcular a comissão apenas quando a taxa de serviço mudar
        const valorComissao = calcularComissao(value);

        setValoresTotais((prevState) => ({
            ...prevState,
            taxaDeServico: value,
            comissao: valorComissao, // Atualiza o valor da comissão
        }));

        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        if (value < 2800) {
            timeoutRef.current = setTimeout(() => {
                warning();
            }, 1000); // 1 segundo de timeout
        }
    };

    // Função para calcular a comissão com base na taxa de serviço
    const calcularComissao = (taxa) => {
        if (!taxa || isNaN(taxa)) return 0;
        return taxa * 0.039; // 3.9% de comissão
    };

    // useEffect para calcular a comissão quando o componente for montado
    useEffect(() => {
        if (valoresTotais.taxaDeServico) {
            const valorComissao = calcularComissao(valoresTotais.taxaDeServico);
            setValoresTotais((prevState) => ({
                ...prevState,
                comissao: valorComissao,
            }));
        }
    }, [valoresTotais.taxaDeServico, setValoresTotais]); // Executa quando a taxa de serviço muda

    return (
        <div className='flex flex-col gap-2 text-md border-card bg-black p-4'>
            <p>
                <span className="flex items-center gap-1">
                    {t('gerar_proposta.taxa_servico')}
                    <Tooltip title={t('gerar_proposta.taxa_servico_alert')}>
                        <AiOutlineInfoCircle className='icon-question' />
                    </Tooltip>
                </span>
            </p>
            <div className="flex items-center gap-2">
                <Input
                    placeholder="Taxa de Serviço (€)"
                    value={valoresTotais.taxaDeServico}
                    onChange={handleTaxaDeServicoChange}
                    type="number"
                />
                {valoresTotais.taxaDeServico !== '' && (
                    <div className="flex items-center gap-1">
                        {valoresTotais.taxaDeServico < 2800 && <span className="text-3xl">😔</span>}
                        {valoresTotais.taxaDeServico >= 2800 && valoresTotais.taxaDeServico < 3000 && <span className="text-3xl">😁</span>}
                        {valoresTotais.taxaDeServico >= 3000 && <span className="text-3xl">🤑</span>}
                    </div>
                )}
            </div>
            {dataUser.language == "pt" && (
                <>
                    <hr />
                    <div className="flex items-center justify-between gap-2">
                        <span className="flex gap-1 text-sm justify-center items-center">
                            {t('gerar_proposta.comissao')}
                            <Tooltip title={t('gerar_proposta.comissao_alert')}>
                                <AiOutlineInfoCircle className='icon-question' />
                            </Tooltip>
                        </span>
                        <span className="flex text-sm font-bold justify-center items-center">
                            {valoresTotais.taxaDeServico !== '' && `${valoresTotais.comissao}€`}
                        </span>
                    </div>
                </>
            )}
        </div>
    );
};

export default TaxaDeServico;
