import React, { useEffect } from 'react';
import { Form, Input, Select, Checkbox, Collapse } from 'antd';

const { Option } = Select;
const { Panel } = Collapse;

const FormCalculoProposta = ({ isvIucData, onFormChange, propostaInfo }) => {
    const [form] = Form.useForm(); // Cria uma instância do formulário

    // Atualiza os valores do formulário quando isvData mudar
    useEffect(() => {

        form.setFieldsValue({
            ...isvIucData,
            wltp: isvIucData?.wltp, // Garante que o valor do checkbox seja booleano
            hibridoPlugIn: isvIucData?.hibridoPlugIn,
        });

        // Valida manualmente os campos quando os valores são inválidos
        if (isvIucData.emissoesCO2 == '0' || isvIucData.emissoesCO2 === 0 || isvIucData.emissoesCO2 === "") {
            form.validateFields(['emissoesCO2']);
        }
        if (isvIucData.cilindrada == '0' || isvIucData.cilindrada === 0 || isvIucData.cilindrada === "") {
            form.validateFields(['cilindrada']);
        }
        if (isvIucData.dataPrimeiroRegisto == "" || !isvIucData.dataPrimeiroRegisto) {
            form.validateFields(['dataPrimeiroRegisto']);
        }

    }, [isvIucData, form]);


    return (
        <div className='card grid gap-4'>
            <div className='flex justify-between items-center gap-4'>
                <div className='grid gap-1'>
                    <p className='text-lg font-semibold'>Cálculo do ISV</p>
                </div>
            </div>
            <hr />
            <Form
                layout="vertical"
                className='form-de-envio-docs'
                form={form} // Define o formulário a ser utilizado
                onValuesChange={(_, allValues) => {
                    // console.log(allValues)
                    onFormChange(allValues); // Dispara ao editar algum valor
                }}
            >
                <div className="grid gap-4">
                    <div className='grid grid-cols-1 sm:grid-cols-2 gap-4 text-white'>

                        {/* Campo Cilindrada */}
                        {isvIucData.tipoCombustivel !== "Eletrico" && (
                            <div className='flex flex-col items-stretch gap-2'>
                                <p className='text-white font-semibold'>Cilindrada</p>
                                <Form.Item
                                    name="cilindrada"
                                    rules={[
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value || value === '' || value === '0' || value === 0) {
                                                    return Promise.reject('Por favor, insira um valor válido para a cilindrada!'); // Combinação da validação obrigatória e valor válido
                                                }
                                                return Promise.resolve();
                                            },
                                        }),
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </div>
                        )}

                        {/* Campo Estado */}
                        <div className='flex flex-col items-stretch gap-2'>
                            <p className='text-white font-semibold'>Estado</p>
                            <Form.Item
                                name="novoUsado"
                                rules={[
                                    { required: true, message: 'Por favor, selecione o estado!' },
                                ]}
                            >
                                <Select>
                                    <Option value="1">Novo</Option>
                                    <Option value="0">Usado</Option>
                                </Select>
                            </Form.Item>
                        </div>

                        {/* Campo Tipo de Combustível */}
                        <div className='flex flex-col items-stretch gap-2'>
                            <p className='text-white font-semibold'>Tipo de Combustível</p>
                            <Form.Item
                                name="tipoCombustivel"
                                rules={[
                                    { required: true, message: 'Por favor, selecione o tipo de combustível!' },
                                ]}
                            >
                                <Select>
                                    <Option value="Gasolina">Gasolina</Option>
                                    <Option value="Gasoleo">Gasoleo</Option>
                                    <Option value="Eletrico">Elétrico</Option>
                                </Select>
                            </Form.Item>
                        </div>

                        {/* Campo Emissões */}
                        {isvIucData.tipoCombustivel !== "Eletrico" && (
                            <div className='flex flex-col items-stretch gap-2'>
                                <p className='text-white font-semibold'>Emissões CO2 (g/km)</p>
                                <Form.Item
                                    name="emissoesCO2"
                                    rules={[
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value) {
                                                    return Promise.reject('Por favor, insira as emissões de CO2!'); // Mensagem para campo vazio
                                                }
                                                if (value === '0' || value === 0) {
                                                    return Promise.reject('Por favor, insira um valor válido para as emissões de CO2!'); // Mensagem para valor inválido (0)
                                                }
                                                return Promise.resolve();
                                            },
                                        }),
                                    ]}
                                >
                                    <Input type='number' />
                                </Form.Item>
                            </div>
                        )}

                        {/* Híbrido Plug-In como Checkbox */}
                        {isvIucData.tipoCombustivel !== "Eletrico" && (
                            <div className='flex flex-col items-stretch gap-2'>
                                <Form.Item
                                    name="hibridoPlugIn"
                                    valuePropName="checked"
                                >
                                    <div className='flex items-center gap-2'>
                                        <Checkbox checked={isvIucData?.hibridoPlugIn == "1"} />
                                        <p className='text-white font-semibold'>Híbrido Plug-In</p>
                                    </div>
                                </Form.Item>
                            </div>
                        )}

                        {/* WLTP como Checkbox */}
                        {isvIucData.tipoCombustivel !== "Eletrico" && (
                            <div className='flex flex-col items-stretch gap-2'>
                                <Form.Item
                                    name="wltp"
                                    valuePropName="checked"
                                >
                                    <div className='flex items-center gap-2'>
                                        <Checkbox checked={isvIucData?.wltp == "1"} />  {/* Verifique se wltp é igual a "1" */}
                                        <p className='text-white font-semibold'>WLTP</p>
                                    </div>
                                </Form.Item>
                            </div>
                        )}

                        {/* Data 1º Registo */}
                        <div className='flex flex-col items-stretch gap-2'>
                            <p className='text-white font-semibold'>Data 1º Registo</p>
                            <Form.Item
                                name="dataPrimeiroRegisto"
                                rules={[
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || value === '') {
                                                return Promise.reject('Por favor, insira a data do primeiro registo!'); // Combina a validação de campo obrigatório e validação personalizada
                                            }
                                            return Promise.resolve();
                                        },
                                    }),
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </div>


                        {/* Campo Particulas */}
                        {isvIucData.tipoCombustivel === "Gasoleo" && (
                            <div className='flex flex-col items-stretch gap-2'>
                                <p className='text-white font-semibold'>Particulas</p>
                                <Form.Item
                                    name="particulas"
                                    rules={[
                                        { required: true, message: 'Por favor, selecione as particulas!' },
                                    ]}
                                >
                                    <Select>
                                        <Option
                                            value="">Selecione</Option>
                                        <Option value="0">{'<'} 0,001
                                            g/km</Option>
                                        <Option value="1">{'>'}= 0,001
                                            g/km</Option>
                                    </Select>
                                </Form.Item>
                            </div>
                        )}

                    </div>
                </div>

                <hr />

                <Collapse>
                    <Panel header={<span className="custom-collapse-header">Mais Detalhes</span>} key="1">
                        <div className='grid gap-4'>
                            {/* Campo Tipo de Veículo */}
                            <div className='grid gap-2'>
                                <p>Tipo de Veículo</p>
                                <Form.Item name="tipoDeVeiculoParaOutros">
                                    <Select
                                        style={{ width: '100%' }}
                                        placeholder="Selecione"
                                        value={isvIucData?.tipoDeVeiculoParaOutros || 'LigeiroPassageiros'}
                                    >
                                        <Option value="LigeiroPassageiros">Ligeiro de Passageiros</Option>
                                        <Option value="LSup2500kg">Ligeiro {'>'} 2500Kg</Option>
                                        <Option value="LMeMerc">Ligeiro Misto (cx 145x130), Mercadorias 4x2</Option>
                                        <Option value="LMercCF">Ligeiro Mercadorias Caixa Fechada</Option>
                                        <Option value="LMerc3Lug">Ligeiro Mercadorias 3 Lugares</Option>
                                        <Option value="LMerc4x4">Ligeiro Mercadorias 4x4</Option>
                                        <Option value="LMerc4x2PB3500">Ligeiro Mercadorias, Cx.Fechada s/Cab. Integrada, PB=3500kg</Option>
                                        <Option value="LMercNT">Ligeiro Mercadorias Não Tributado pela Tabela B</Option>
                                        <Option value="LPGPL">Ligeiro Passageiros a GPL</Option>
                                        <Option value="LPGN">Ligeiro Passageiros a Gás Natural</Option>
                                        <Option value="Taxi">Táxi</Option>
                                        <Option value="Autocaravana">Autocaravana</Option>
                                        <Option value="MotoTriQuad">Motociclo, Triciclo, Quadriciclo</Option>
                                    </Select>
                                </Form.Item>
                            </div>

                            {/* Campo Isenção */}
                            <div className='grid gap-2'>
                                <p>Isenção</p>
                                <Form.Item name="tipoDeVeiculoParaInsencoes">
                                    <Select
                                        style={{ width: '100%' }}
                                        placeholder="Selecione"
                                        value={isvIucData?.tipoDeVeiculoParaInsencoes || ''}
                                    >
                                        <Option value="">Sem Isenção</Option>
                                        <Option value="familias_numerosas">Famílias numerosas</Option>
                                        <Option value="mudanca_residencia">Mudança de residência</Option>
                                        <Option value="pessoas_com_deficiência">Pessoas com deficiência</Option>
                                        <Option value="pessoas_com_deficiência_obriga_caixa_automatica">Pessoas com deficiência que obriga caixa automática</Option>
                                    </Select>
                                </Form.Item>
                            </div>

                            {/* Campo Outros Serviços */}
                            {/* <div className='grid gap-2'>
                                <p>Outros Serviços</p>
                                <Form.Item name="outrosServicos">
                                    <Select
                                        style={{ width: '100%' }}
                                        placeholder="Selecione"
                                        value={isvIucData?.outrosServicos || undefined}
                                    >
                                        <Option value="">Selecione</Option>
                                        <Option value="Legalização">Legalização</Option>
                                        <Option value="Legalização e Transporte">Legalização e Transporte</Option>
                                        <Option value="Sem outro serviço">Sem Outro Serviço</Option>
                                    </Select>
                                </Form.Item>
                            </div> */}

                        </div>
                    </Panel>
                </Collapse>
            </Form>
            {propostaInfo.erro && (
                <span className='text-sm text-[#fffd00] opacity-90'>{propostaInfo.erro}</span>
            )}

        </div>
    );
};

export default FormCalculoProposta;
