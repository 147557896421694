import React, { useState } from 'react';
import { Button, Modal } from 'antd'; // Certifique-se de que está importando os componentes corretos
import logoIcon from '../../../assets/hubspot-icon.svg'; // Corrija o caminho da imagem do logo
import AssociarDeal from './AssociarProposta/AssociarDeal';
import { useTranslation } from 'react-i18next';



export default function AssociarPropostaAoDeal({ dataUser, dataCarros, setHubspotInfo, setPropostaInfo, propostaInfo }) {
    
    const { t } = useTranslation();

    // Estado para controlar a visibilidade do modal
    const [isModalOpen, setIsModalOpen] = useState(false);

    // Função para mostrar o modal
    const showModal = () => {
        setIsModalOpen(true);
    };

    // Função para fechar o modal
    const handleOk = () => {
        setIsModalOpen(false);
    };

    // Função para cancelar/fechar o modal
    const handleCancel = () => {
        setIsModalOpen(false);
    };


    return (
        <div className='flex gap-4 justify-between w-full'>

            <p className='text-lg font-bold'>{t('gerar_proposta.proposta_n')}: {propostaInfo.idProposta}</p>
            <Button
                className="flex gap-2 text-black items-center font-bold rounded p-2 w-max hubspot-btn"
                onClick={showModal}
            >
                <img
                    src={logoIcon}
                    alt="Logo"
                    className="w-6 object-contain"
                />
                {t('gerar_proposta.associar_proposta')}
            </Button>

            <Modal
                title={null}
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                okText="Fechar"
                footer={null}
                width={1000}
                className="hubspot-modal"
            >
                <AssociarDeal
                    dataUser={dataUser}
                    dataCarros={dataCarros}
                    setIsModalOpen={setIsModalOpen}
                    setHubspotInfo={setHubspotInfo}
                    propostaInfo={propostaInfo}
                    setPropostaInfo={setPropostaInfo}
                />
            </Modal>
        </div>
    );
}
