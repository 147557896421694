import React, { useState, useEffect } from 'react';
import { Layout, Button, Popover } from 'antd';
import { GoSidebarCollapse, GoSidebarExpand } from "react-icons/go";
// import { useNavigate } from 'react-router-dom';
import { IoMdNotifications, IoMdNotificationsOutline } from "react-icons/io";
import axios from 'axios';
import UserPopover from '../components/User/UserPopover';
import PopupNotificacoes from '../components/Notificacoes/PopupNotificacoes';
import flagPortugal from '../assets/flags/flag-portugal.svg'
import flagEspanha from '../assets/flags/flag-spain.svg'
import { RiArrowRightSLine, RiArrowLeftSLine } from "react-icons/ri";


const { Header } = Layout;

export default function CustomHeader({
  notificacaoState,
  collapsed,
  onToggle,
  dataUser,
}) {
  // const { t } = useTranslation();
  // const navigate = useNavigate();

  // Estado para a busca e o popover de notificações
  // const [searchQuery, setSearchQuery] = useState('');
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const [notificacoesCarregadas, setNotificacoesCarregadas] = useState(false);

  // useEffect(() => {
  //   if (searchQuery) {
  //     navigate(`/search?query=${searchQuery}`);
  //   }
  // }, [searchQuery, navigate]);

  const handlePopoverClick = async () => {
    setIsPopoverVisible(prev => !prev);
    if (!notificacoesCarregadas && !isPopoverVisible) {
      try {
        const url = `${process.env.REACT_APP_URL_NOTIFICACOES}?token=${dataUser.saveToken}&user_id=${dataUser.iDTokenAuthenticated}&aberta=1`;
        await axios.get(url);
        setNotificacoesCarregadas(true);
        // Presume-se que as notificações carregadas atualizam o estado global de notificações e contagem
      } catch (error) {
        console.error('Error fetching notifications:', error);
      }
    }
  };


  return (
    <Header className="flex justify-between items-center p-4 gap-4 header-main">
      <div className='flex items-center gap-4'>
        <Button type="primary" onClick={onToggle} className='h-full'>
          {collapsed ? <RiArrowRightSLine size={20} /> : <RiArrowLeftSLine size={20}  />}
        </Button>
      </div>
      <div className='flex items-center gap-4'>
        <img
          src={dataUser.language == "pt" ? flagPortugal : flagEspanha}
          alt={dataUser.language == "pt" ? "Portugal" : "España"}
          className='border-rounded'
          style={{
            width: '35px',
            height: '35px',
            border: '1px solid #fff',
            borderRadius: '50px',
            lineHeight: '37px',
            fontSize: '18px',
            padding: '4px'
          }}
        />
        {Array.isArray(notificacaoState.notificacoes) && notificacaoState.notificacoes.length > 0 && (
          <Popover
            placement="bottomRight"
            content={<PopupNotificacoes notificacoes={notificacaoState.notificacoes} />}
            trigger="click"
            onClick={handlePopoverClick}
          >
            <div className="relative flex gap-2 items-center cursor-pointer">
              <div className="border border-[#575757] text-white py-[4px] px-[15px] rounded transition-colors duration-300 ease-in-out flex items-center">
                {isPopoverVisible ? (
                  <IoMdNotifications size={20} />
                ) : (
                  <IoMdNotificationsOutline size={20} />
                )}
                {notificacaoState.countNotificacoes > 0 && (
                  <div className="absolute top-[-10px] right-[-10px] bg-red-600 text-white text-xs rounded-full h-5 w-5 flex items-center justify-center">
                    {notificacaoState.countNotificacoes}
                  </div>
                )}
              </div>
            </div>
          </Popover>
        )}

        <UserPopover
          dataUser={dataUser}
        />
      </div>
    </Header>
  );
}
