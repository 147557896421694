import { Button } from 'antd';
import React from 'react';
import { IoEyeOutline } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import logo from '../../../assets/hubspot.svg';
import { useTranslation } from 'react-i18next';


export default function InfoPerfilClienteHubspotProposta({ hubspotInfo }) {


    const { t } = useTranslation();


    return (
        <div className='card grid gap-4'>

            <div className={`flex items-center gap-4`}>
                <img
                    src={logo}
                    alt="Logo"
                    className="w-24 h-10 object-contain"
                />
                <span>|</span>
                <span className='text-lg font-semibold'>{t('hubspot.info_deal')}</span>
            </div>

            <hr />

            <div className='card-white grid gap-4'>

                <div>
                    <p className="text-black text-base flex justify-between">
                        {t('hubspot.cliente')}: <span className="font-bold capitalize">{hubspotInfo.nomeDoClienteDeal}</span>
                    </p>
                    <p className="text-black text-base flex justify-between">
                        {t('hubspot.email')}: <span className="font-bold">{hubspotInfo.emailDoClienteDeal}</span>
                    </p>
                    <p className="text-black text-base flex justify-between">
                        {t('hubspot.etapa')}: <span className="font-bold">{hubspotInfo.etapaDoDeal}</span>
                    </p>
                    <p className="text-black text-base flex justify-between">
                        {t('hubspot.pipeline')}: <span className="font-bold">{hubspotInfo.pipelineDoDeal}</span>
                    </p>
                </div>
                <hr />
                <div className="flex justify-end">
                    <Link to={`/${t('menu.hubspot').toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/ /g, '-')}/${t('hubspot.perfil_deal').toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/ /g, '-')}/?id_deal=${hubspotInfo.idDoDeal}`}>
                        <Button
                            type="primary"
                            target="_blank"
                            className="flex gap-2 items-center bg-white text-black font-bold rounded"
                        >
                            <IoEyeOutline />
                            {t('hubspot.ver_perfil')}
                        </Button>
                    </Link>
                </div>
            </div>
        </div>
    );
}
